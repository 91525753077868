import { LICENSE_PLATES } from "./license_plates";

const TASKS = {
    classification: {
        gun: {
            id: 31,
            header: 'Gun classification',
            description: `
                In this task you will be presented with a highlighted gun, and be asked to characterize the following
                properties of the gun as well as the overall scene:
                <ul>
                    <li><b>Gun type:</b><ul>
                        <li><b>Not a gun</b> -- If this is clearly not a real gun, then please mark this. This would include things such as paintball guns, tasers, and anything else that is not a firearm. This also includes things such as Rocket Propelled Grendes (RPGs), mounted artillery, as well as tank barrells.</li>
                        <li><b>Short</b> -- Pistols, handguns, and any other gun with a short barrell that has the potential to be operated with one hand.</li>
                        <li><b>Long</b> -- Rifles, shotguns, machine guns and any other gun with a long barrell that typically needs two hands to operate.</li>
                        <li><b>Unsure</b> -- If you're unsure whether this is a gun, then please select this option.</li>
                        <li><b>Unknown/Other gun type</b> -- If you believe it is a gun but struggle to classify it into long or short, then please mark this option.</li>
                    </ul></li>
                    <li><b>Holstered:</b> If the gun (typically a pistol) is holstered, then pleae check this box. You will typically find holstered guns on the hips of a person, though be on the lookout for them elsewhere!</li>
                    <li><b>Indoors:</b> If the scene is not outdoors/does not take place in natural sun light, then please check this box. This includes images such as stock images that have uniform backgrounds.</li>
                    <li><b>Nighttime:</b> If the scene is outdoors, and it is nighttime, then please check this box.</li>
                </ul>
            `,
            workflow: [
                'Select the type of gun highlighted in the image from the dropdown. If you are unsure of the gun type then please mark <code>Unknown</code>',
                'If the gun is holstered, please check the <code>Holstered?</code> box.',
                'If the scene is indoors, please check the <code>Indoors?</code> box.',
                'If the scene is outdoors, and it is night time, then please check the <code>Nighttime?</code> box. Use your best judgement on scenes taking place at dusk.'
            ],
            rules: [
                {
                    header: 'Select the gun type from the dropdown list',
                    description: `
                        If you can determine the gun belongs in the <code>Long</code> or <code>Short></code> categories,
                        then please select one of these. Otherwise, please select the most applicable category from
                        <code>Not a gun</code>, <code>Unsure if gun</code> and <code>Other/Unknown gun type</code>.
                    `,
                    examples: [
                        {
                            description: 'A <code>Short</code> gun.',
                            positive: true,
                            image: 'short1.jpg'
                        },
                        {
                            description: 'A <code>Long</code> gun.',
                            positive: true,
                            image: 'long1.jpg'
                        },
                        {
                            description: 'Another <code>Long</code> gun.',
                            positive: true,
                            image: 'long2.jpg'
                        },
                        {
                            description: 'This is a logo, so it is <code>Not a gun</code>.',
                            positive: true,
                            image: 'reject1.jpg'
                        },
                        {
                            description: 'This is <code>Not a gun</code>.',
                            positive: true,
                            image: 'reject2.jpg'
                        },
                        {
                            description: 'This is a rocket propelled grenade (RPG) launcher, please select <code>Not a gun</code> for this.',
                            positive: true,
                            image: 'rpg.jpg'
                        },
                        {
                            description: 'This an example of mounted artillery, and is considered <code>Not a gun</code> for this task.',
                            positive: true,
                            image: 'artillery.jpg'
                        },
                        {
                            description: 'It is hard to make out what this is, please select <code>Unsure if gun</code> for samples such as this.',
                            positive: true,
                            image: 'unsure1.jpg'
                        },
                        {
                            description: 'It is hard to make out what this is, please select <code>Unsure if gun</code> for samples such as this.',
                            positive: true,
                            image: 'unsure2.jpg'
                        }
                    ]
                },
                {
                    header: 'Check <code>Holstered?</code> for holstered guns',
                    description: 'If the highlighted gun is holstered, please check this box.',
                    examples: [
                        {
                            description: 'This is a short, holstered gun.',
                            positive: true,
                            image: 'short_holstered_indoors.jpg'
                        }
                    ]
                }
            ]
        },
        logo: {
            id: 4,
            header: 'Logo classification',
            description: `
                You'll be presented with an image that contains a single boxed candidate logo. We are showing the boxed logo in the entire image rather than the cropped logo so that you may use context clues to better identify the logo. A logo is a graphic symbol or other design adopted by an organization to
                identify its products, uniform, vehicles, or other associated material. You'll first select the broad category of the logo/non-logo, outlined below:
                <ul>
                    <li><b>Category:</b><ul>
                        <li><b>Non-Logo</b> -- Anything that would not be considered a logo that has been boxed such as plain text.
                        <li><b>Logos</b> -- Anything outlined in the definition of logo above, so something that you would easily be able to re-identify across different images. If this is selected, a free form text box will appear where you will attempt to define the "brand" of logo. This is pre-populated with many popular brands and sports teams, which should auto fill if you begin typing the brand. If you cannot find the brand and are confident that you can define it, then please go ahead and do so; otherwise, please enter "unknown" for the brand.</li>
                        <li><b>Unsure</b> -- Select this if you're unsure of what category it falls into. Please use this as a fallback if you cannot determine the overlying category. This is not to be used if you know this is a logo, but do not know what the brand is. Only use this if you are unsure what the overlying category is.</li>
                        <li><b>Watermark</b> -- These usually appear on the edges of images and are CGI overlays on the actual image.</li>
                        <li><b>Instructional signage</b> -- This includes street/road signs, building signs such as "EXIT", bathrooms, etc. </li>
                        <li><b>License plates</b> -- This is for the actual license plate box itself, not boxes of text/logos within the license plate.</li>
                        <li><b>Government Agencies</b> -- This is to encompass anything which is boxed related to government agencies (police, paramedics, etc.) including patches, police insignia, logos, and stylized text. This includes "POLICE" written on police vests and on police cars.
                        <li><b>Flags</b></li>
                        <li><b>Tattoos</b></li>
                </ul>
                In addition to the above, you'll be asked to select the location in which the box appears, one of "vehicle", "person" and "environment" (not on a vehicle or person).

                Lastly you'll be asked to check boxes when applicable for:
                <ul>
                    <li><b>low quality</b> -- indicates that the logo is blurry, pixelated, low contrast, or otherwise just hard to make out</li>
                    <li><b>occluded</b> -- indicates that the logo is partially occluded by another object in the scene, partially off frame, or partially missing for some reason, like peeling off</li>
                    <li><b>questionable</b> -- indicates that you are unsure if you've correctly classified the logo and would like someone to take a second look. Please do not hesitate to use this option.</li>
                    <li><b>reflection</b> -- Indicates that the logo is upside-down/mirrored on a surface.</li>
                </ul>

                In addition to the above, please take special note of the below cases:
                <ul>

                    <li>A logo does not need to have an obvious brand label to be classified as a logo; a logo is just a graphic or stylized text that is recognizable enough that you could match it to a high resolution sample of the same type. Under <code>Category</code>, please select <code>Logo</code>, then in the box for brand type "unknown" for any detection that you are confident is a logo (not plain text) and brand cannot be determined. Please select <code>Logo</code> and under <code>brand</code> type in the brand name and select the applicable brand if you can read/identify the brand and it appears in pre-populated list. Please select <code>Logo</code> and enter a custom label if you can read/identify the logo, but the brand does not appear in pre-populated list.</li>
                    <li>Under <code>Category</code>, mark unsure if you're unable to determine if a detection is a logo or plain text.</li>
                    <li>Regions within license plate boxes (not the plate box itself) should be annotated for logos or plain text. Graphics and stylized text on license plates should be categorized as logos.</li>
                    <li>Label vehicle badges as their actual text, not the make that the badge is associated with. Ex. A 325 is badge on a BMW you would select <code>Logo</code> and in the box for brand type "325" and would not type "bmw". The white and blue BMW emblem on hood you would select <code>Logo</code> and in the box for brand type "bmw". Select <code>Logo</code> and in the box for brand type "unknown" for vehicle badges that can’t be read or identified.</li>
                    <li>Logos that can be identified using context clues should be marked as their respective logo. Ex. A BMW logo is unreadable, but by the body of the car you can clearly tell this is a BMW should be marked under brand "bmw". Please be sure to mark "occluded" and "poor quality" as relevant. </li>
                    <li>If the logo is readable, but you don't know the brand, please select logo, but write in the readable, stylized text. </li>
                    <li>Please note the difference between selecting <code>Non-Logo</code>, <code>Unsure</code>, and <code>Logo</code> then typing in "Unknown": </li>
                    <ul>
                        <li><code>Non-Logo</code> should be selected from the Category dropdown if the box is clearly around a non-logo such as plain text.</li>
                        <li><code>Unsure</code> should be selected if you are unsure of the overlying category that the boxed image falls under. One example would be if you are unsure if it is considered plain text or stylized text. </li>
                        <li><code>Logo</code> then typing in "Unknown" should be used if you know that this is a logo, but do not know what brand this is.</li>
                    </ul>
                </ul>

            `,
            workflow: [
                'Select the category of the box in the image from the dropdown. If you are unsure of the category type then please mark <code>Unsure</code>',
                'If you selected <code>Logo</code>, in the box, type the brand of the logo, choosing the applicable logo from the dropdown, or typing your own if the brand is not in the dropdown.',
                'Select where the logo is located (on a person, vehicle, or the environement)',
                'If the logo is low quality, occluded, questionable, or a reflection, click the applicable box.'
            ],
            rules: [
                {
                    header: 'Understand the difference between stylized text and plain text',
                    description: `
                        If a box is around text, it is important to know the difference between stylized text and plain text.
                        Plain text would be any text that is just plainly there. Stylized text would be considered anything that
                        is italicized, colored, emphasized in any way.
                    `,
                    examples: [
                        {
                            description: 'Stylized Text Example: Should be marked as "government agency"',
                            positive: true,
                            image: 'stylizedText.jpg'
                        },
                        {
                            description: 'Stylixed Text Example: Should be marked as "logo"',
                            positive: true,
                            image: 'stylizedText2.jpg'
                        }
                    ]
                },
                {
                    header: "Government Agency Specifics",
                    description: `
                        For government agency images, when the boxed logo is related to a government agency (ie Police, firefighters, FBI, etc), please select "Government agency" from the drop down.
                    `,
                    examples: [
                        {
                            description: 'Police Badges with names: Should be marked "Government Agency"',
                            positive: true,
                            image: 'badge.jpg'
                        },
                        {
                            description: 'Patches on Uniforms: Should be marked "Government Agency"',
                            positive: true,
                            image: "patch.jpg"
                        },
                        {
                            description: 'Text on Police Officer: Should be marked as "government agency"',
                            positive: true,
                            image: 'plainText.jpg'
                        },
                    ]
                },
                {
                    header: "Using Questionable",
                    description: `
                        It is important to note that we want as few logos labeled as unknown or unsure as possible. By selecting "Questionable", the image will be queued for another annotator to look at.
                    `,
                    examples: [
                        {
                            description: 'Sports teams are often identifiable by other annotators. If you see a sports team and cannot match it to the logos we provide in the instructions, this is a good case to write unknown and mark questionable',
                            positive: true,
                            image: 'questionable.jpg'
                        },
                    ]
                },
                {
                    header: "Additional things to note",
                    description: `Additionally, please take note of these special cases`,
                    examples: [
                        {
                            description: 'Identifiable brand styles are considered logos. This Adidas shoe has the three lines that is identifiably Adidas. In this case, we would want this marked <code>adidas</code>. This also is true for other brands such as Vans.',
                            positive: true,
                            image: 'adidas.jpg'
                        },
                        {
                            description: 'Only the license plate itself should be labeled "license_plate". The inner boxes should be labeled for plain text (New York) and logos for the New York shape in the middle',
                            positive: true,
                            image: 'license_plate.jpg'
                        },
                        {
                            description: 'When you do not know the brand, but there is readable, stylized text, please label it logo and type in the readable text. In this case, this would be <code>keystone</code>',
                            positive: true,
                            image: 'keystone.jpg'
                        }
                    ]
                },
                {
                    header: "Sports Teams",
                    description: `
                    We typically see a lot of sports team logos, please try and identify the team using the below as reference. If you are not sure, please mark questionable, because sports teams may be identifiable by another annotator. These are not all inclusive lists, just the most popular logos.
                    `,
                    examples: [
                        {
                            description: 'NBA Teams',
                            positive: true,
                            image: 'nba.jpg'
                        },
                        {
                            description: 'NFL Teams',
                            positive: true,
                            image: 'nfl.jpg'
                        },
                        {
                            description: 'MLB Teams',
                            positive: true,
                            image: 'mlb.jpg'
                        },
                        {
                            description: 'Popular College Teams',
                            positive: true,
                            image: 'college.jpg'
                        },
                        {
                            description: 'NHL Teams',
                            positive: true,
                            image: 'nhl.jpg'
                        }
                    ]
                },
                {
                    header: "Sports Teams",
                    description: `
                    We typically see a lot of sports team logos, please try and identify the team using the below as reference. If you are not sure, please mark questionable, because sports teams may be identifiable by another annotator. These are not all inclusive lists, just the most popular logos.
                    `,
                    examples: [
                        {
                            description: 'NBA Teams',
                            positive: true,
                            image: 'nba.jpg'
                        },
                        {
                            description: 'NFL Teams',
                            positive: true,
                            image: 'nfl.jpg'
                        },
                        {
                            description: 'MLB Teams',
                            positive: true,
                            image: 'mlb.jpg'
                        },
                        {
                            description: 'Popular College Teams',
                            positive: true,
                            image: 'college.jpg'
                        },
                        {
                            description: 'NHL Teams',
                            positive: true,
                            image: 'nhl.jpg'
                        }
                    ]
                }
            ]
        }
    },
    confirmation: {
        face_background: {
            id: 41,
            header: 'Background confirmation',
            confirmation: 'Is this background properly segmented?',
            workflow: []
        },
        face_background_uniform: {
            id: 42,
            header: 'Background uniformity',
            confirmation: 'Is the background uniform?',
            workflow: []
        },
        character: {
            id: 29,
            header: 'Character confirmation',
            confirmation: 'Are all characters correctly marked?',
            workflow: [
                'Skip images that contain backwards characters, or characters that are stacked vertically on top of each other.',
                'Mark <code>Yes</code> (up arrow) if all characters, and only characters are relatively well boxed within the image.',
                'Mark <code>No</code> (down arrow) if any characters are not boxed, or anything other than a character is boxed.',
                'If unsure, skip the image using the <code>Skip</code> button or <code>s</code> key.',
                'Hit <code>Next</code> (spacebar and right arrow keys) to proceed to the next image.'
            ]
        },
        face_age: {
            id: 28,
            header: 'Age confirmation',
            confirmation: 'Is this age correct?',
            workflow: [
                'Mark <code>Yes</code> (up arrow) if you believe there is strong evidence that the person is the presented age.',
                'Mark <code>No</code> (down arrow) if you do not beleive that the person is the presented age.',
                'If unsure, skip the image using the <code>Skip</code> button or <code>s</code> key.',
                'Hit <code>Next</code> (spacebar and right arrow keys) to proceed to the next image.'
            ]
        },
        face_detection: {
            id: 16,
            header: 'Face confirmation',
            confirmation: 'Are all faces correctly boxed?',
            workflow: [
                'Mark <code>Yes</code> (Up arrow) if all faces, regardless of size are well boxed within the image.',
                'Mark <code>No</code> (Down arrow) if any faces are unboxed or have poor boxes within the image.',
                'If unsure, skip the image by not marking <code>Yes</code> or <code>No</code>.',
                'Hit <code>Next</code> (spacebar and right arrow keys) to proceed to the next image.',
                `Things to look out for when marking <code>No</code>:<ul>
                <li>Occosionally you may see small errnoeous boxes. If one of these appear then please mark <code>No</code>.</li>
                <li>Generally, if an image has many small faces in the background, lean towards marking <code>No</code>.</li>
                <li>Any face in the image has a significant portion visible (one of the eyes, mouth, etc.) and is not boxed.</li>
                <li>Any small background faces are unboxed. Be very diligent in checking this on images with complex/large backgrounds. If there are small (even barely visible) unboxed faces in the background, please mark <code>No</code>.</li>
                <li>Cartoon faces, statues, and animal faces are not considered faces, and if the image has any of these boxed as a face please select <code>No</code>.</li>
                </ul>`
            ]
        },
        face_landmarks: {
            id: 8,
            header: 'Landmarks accuracy',
            confirmation: 'Are all landmarks marked accurately?',
            workflow: [
                'Mark <code>Yes</code> (Up arrow) if all six landmarks are accurately marked on the face.',
                'Mark <code>No</code> (Down arrow) if any landmark is poorly marked',
                'Hit <code>Next</code> to proceed to the next image.',
                `The six landmarks should appear in this order, if they don't then please mark <code>No</code>:<ol>
                <li>Left eye (person's right eye) center.</li>
                <li>Right eye (person's left eye) center.</li>
                <li>Nose tip</li><li>Left mouth corner (person's right mouth corner).</li>
                <li>Right mouth corner (person's left mouth corner).</li>
                <li>Chin bottom.</li>
                </ol>`
            ]
        },
        face_liveness: {
            id: 9,
            header: 'Face Liveness',
            confirmation: 'Does image appears to be of a genuine face in front of a camera without post-processing performed on the image?',
            workflow: []
        },
        face_mask: {
            id: 34,
            header: 'Face mask',
            confirmation: 'Is this a mask or other 3d spoof face?',
            workflow: []
        },
        gun: {
            id: 24,
            header: 'Gun confirmation',
            description: `
                In this task, you will be presented an image that has previously been annotated for gun localization,
                and you will be asked whether all guns in the image have been properly marked. If there are any poorly
                marked guns, or guns that are not marked at all (including holstered guns), then please mark <code>No</code>.
                If all guns are properly marked, then please mark <code>Yes</code>. If you are unsure, then mark neither.
            `,
            confirmation: 'Are all guns correctly marked?',
            workflow: [
                'Mark <code>Yes</code> (Up arrow) if all guns, regardless of size are well marked within the image.',
                'Mark <code>No</code> (Down arrow) if any guns are unmarked or have poor markings within the image.',
                'If unsure, skip the image using the <code>Skip</code> button or <code>s</code> key.',
                'Hit "Next" (spacebar and right arrow keys) to proceed to the next image.',
                `Things to look out for when marking <code>No</code>:<ul>
                <li>Any gun in the image that is improperly marked. Each gun should have a relatively tight box placed around the majority of the gun. If the box is not well placed, or a large portion of the gun is not within the box, then please select <code>No</code>.</li>
                <li>Any holstered guns are unmarked. If there is an unmarked, holstered gun (think of a gun on a police officer's hip), then please mark <code>No</code>.</li>
                <li>Any small background guns are unmarked. Be very diligent in checking this on images with complex/large backgrounds.</li>
                <li>Artwork guns are not considered guns, and if the image has any of these marked as a gun please select <code>No</code>.</li>
                </ul>`
            ],
            rules: [
                {
                    header: 'Properly marked guns',
                    description: `
                        A properly marked gun will contain most or all of the gun within the box, with very little
                        excess area contained within the box. The box may extend a bit beyond the gun, but in the case of
                        excessively loose or excessively tight boxes, please mark <code>No</code>.
                    `,
                    examples: [
                        {
                            description: 'The gun is fully contained within the box, with it extending just a bit past the barrel. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'good1.jpg'
                        },
                        {
                            description: 'Both guns are fully contained, and the box extends just to the end of each barrel. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'good2.jpg'
                        },
                        {
                            description: 'The gun is fully contained within the box, with it extending just a bit past the barrel. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'good3.jpg'
                        },
                        {
                            description: 'The gun is fully contained within the box, with it extending just a bit past the barrel. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'good4.jpg'
                        },
                        {
                            description: 'The gun is fully contained within the box, with it extending just a bit past the barrel. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'good5.jpg'
                        }
                    ]
                },
                {
                    header: 'Mark <code>No</code> for images with poorly marked or missing guns',
                    description: `
                        Take great care to make sure that all guns within the image have been well marked.
                        If any gun is not well marked, or not marked at all, then please answer <code>No</code>
                        and proceed to the next image. If anything is marked that is not a gun, then please answer
                        <code>No</code>.
                    `,
                    examples: [
                        {
                            description: 'Though this image has one gun properly marked on the right, the gun on the left is not marked so this would be a <code>No</code>.',
                            positive: false,
                            image: 'missing1.jpg'
                        },
                        {
                            description: 'This gun is too loosely marked for our purposes. Please select <code>No</code> for such egregious examples.',
                            positive: false,
                            image: 'loose1.jpg'
                        },
                        {
                            description: 'The gun on the right is properly marked, though the gun on the left is marked much too loosely. Please select <code>No</code> for such examples.',
                            positive: false,
                            image: 'loose2.jpg'
                        },
                        {
                            description: 'This gun is too tightly marked as the handle is not within the box. Please select <code>No</code> for such examples.',
                            positive: false,
                            image: 'tight1.jpg'
                        }
                    ]
                },
                {
                    header: 'If anything is boxed that is not a gun, then please mark <code>No</code> and move along to the next image.',
                    description: `
                        Sometimes portions of the image are boxed even though they are not a gun. Take great care to make sure
                        that every box contains a valid gun, and if not then please mark <code>No</code>.
                    `,
                    examples: [
                        {
                            description: 'The hand on the right is boxed, and since this is not a gun this would be a <code>No</code>.',
                            positive: false,
                            image: 'fp1.jpg'
                        },
                        {
                            description: 'The left area of the image is boxed, and since this is not a gun it would be a <code>No</code>.',
                            positive: false,
                            image: 'fp2.jpg'
                        }
                    ]
                },
                {
                    header: 'Mark <code>No</code> for invalid images',
                    description: `
                        If an image is clearly a cartoon, video game or computer generated, then please mark
                        <code>No</code> before proceeding to the next image. Additionally, please mark
                        <code<No</code> for images that are collages, meaning there are multiple images
                        contained within the image.
                    `,
                    examples: [
                        {
                            description: 'This image is multiple images stiched together, i.e. a collage. Please mark <code>No</code>.',
                            positive: false,
                            image: 'collage.jpg'
                        },
                        {
                            description: 'This is a computer generated image from a video game, please mark <code>No</code>.',
                            positive: false,
                            image: 'cgi.jpg'
                        }
                    ]
                },
            ]
        },
        gun_image: {
            id: 14,
            header: 'Gun confirmation',
            confirmation: 'Are there guns in this image?'
        },
        image: {
            id: 7,
            header: 'Image confirmation',
            confirmation: 'Is this image relevant?',
            workflow: [
                'Mark "Yes" (Up arrow) if image is relevant and meets all three requirements.',
                'Mark "No" (Down arrow) if all three criteria not met.',
                'If you start to notice the same or very similar image repeatedly, please mark “no” for all subsequent copies.',
                'If unsure, skip the image using the "Skip" button or "s" key for any collages (image composed from multiple images) with identifiable logos and any images that have logos that you’re unsure about.',
                'Hit "Next" (spacebar or right arrow key) to proceed to the next image.',
                `Three pieces of criteria:<ul>
                <li>The image was captured by a CCTV surveillance camera, doorbell camera, police body camera, or vehicle dashboard camera.</li>
                <li>There is at least one identifiable logo on a person’s clothing or vehicle in the image. You don’t necessarily need to be able to name the logo brand (bonus if you can), but it should be recognizable enough that you could match it to a higher resolution example of the same logo. Note: police badges and insignia and police vehicle decals count as a logo even if you can’t identify a specific jurisdiction or department.</li>
                <li>The image isn’t overly crowded with background vehicles and persons </li>
                </ul>`
            ],
            rules: [
                {
                    header: 'Body Camera Images with Identifiable Logos',
                    description: `
                        If the image is a body camera image with identifiable logos please press <code>Yes</code>.
                    `,
                    examples: [
                        {
                            description: 'This a police body camera image with identifiable logos on the car. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'policeBodyCam1.jpg'
                        },
                        {
                            description: 'This a police body camera image with identifiable logos on the car. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'policeBodyCam2.jpg'
                        }
                    ]
                },
                {
                    header: 'Dash Camera Images with Identifiable Logos',
                    description: `
                        If the image is a dash camera image with identifiable logos please press <code>Yes</code>.
                    `,
                    examples: [
                        {
                            description: 'This a dash camera image with identifiable logos on the car. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'dashCam1.jpg'
                        },
                        {
                            description: 'This a dash camera image with identifiable logos on the car and people. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'dashCam2.jpg'
                        }
                    ]
                },
                {
                    header: 'CCTV Images with Identifiable Logos',
                    description: `
                        If the image is a CCTV image with identifiable logos please press <code>Yes</code>.
                    `,
                    examples: [
                        {
                            description: 'This a cctv image with identifiable logos on the hat of the person. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'cctv1.jpg'
                        },
                        {
                            description: 'This a cctv image with identifiable logos on the car. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'cctv2.jpg'
                        },
                        {
                            description: 'This a cctv image with identifiable logos on the person. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'cctv3.jpg'
                        }
                    ]
                },
                {
                    header: 'Near Infrared Images with Identifiable Logos',
                    description: `
                        If the image is an infrared image with identifiable logos please press <code>Yes</code>.
                    `,
                    examples: [
                        {
                            description: 'This a door-bell camera infrared image with identifiable logos on the car. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'nearInfrared.jpg'
                        },
                        {
                            description: 'This a cctv infrared image with identifiable logos on the car and people. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'nearinfradred2.jpg'
                        }
                    ]
                },
                {
                    header: 'Skip Collages with Identifiable logos',
                    description: `
                        If the image is a collage then please <code>Skip</code>.
                    `,
                    examples: [
                        {
                            description: 'This a CCTV image with identifiable logos, but is a collage. This would be a <code>Skip</code>.',
                            positive: false,
                            image: 'collageIdentifiable.jpg'
                        },
                        {
                            description: 'This a CCTV image with identifiable logos, but is a collage. This would be a <code>Skip</code>.',
                            positive: false,
                            image: 'collageIdentifiable2.jpg'
                        }
                    ]
                },
                {
                    header: 'Mark No on Near Duplicates',
                    description: `
                        If there are near duplicate images that you have seen, please press <code>No</code> for the second image.
                    `,
                    examples: [
                        {
                            description: 'This a CCTV image with identifiable logos. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'nearDuplicate.jpg'
                        },
                        {
                            description: 'This image is a near duplicate of the one before. This would be a <code>No</code>.',
                            positive: false,
                            image: 'nearDuplicate1.jpg'
                        }
                    ]
                }
            ]
        },
        license_plate: {
            id: 25,
            header: 'License plate confirmation',
            confirmation: 'Are all license plates correctly marked?',
            workflow: [
                'Mark "Yes" (Up arrow) if all license plates, regardless of size are well marked within the image.',
                'Mark "No" (Down arrow) if any license plates are unmarked or have poor markings within the image.',
                'If unsure, skip the image using the "Skip" button or "s" key.',
                'Hit "Next" (spacebar or right arrow key) to proceed to the next image.',
                `Things to look out for when marking "No":<ul>
                <li>Any license plate in the image that is improperly marked. Each license plate should have a relatively tight box placed around the majority of the plate. If the box is not well placed, or a large portion of the plate is not within the box, then please select "No".</li>
                <li>Any small background plates are unmarked. Be very diligent in checking this on images with complex/large backgrounds.</li>
                <li>If there's any doubt like partial plate, too small, blurry, white/blacked out, etc., mark as <code>No</code></li>
                </ul>`
            ],
            rules: [
                {
                    header: 'Obscured Plates and Fragments of Plates',
                    description: `
                        If you know that there is a license plate, it should be boxed, no matter how occluded, blurry or small. If you have any doubts on if this is a plate, it should not be boxed. Some images will have obscured plates that are covered intentionally. If there is a purposefully blurred/blacked
                        out plate that is marked as a plate, please mark <code>No</code>.
                    `,
                    examples: [
                        {
                            description: 'The plate on the left is properly marked, but the plate on the right is a blacked out plate that should not be recognized as a plate. This would be a <code>No</code>.',
                            positive: false,
                            image: 'notValidPlate.jpg'
                        }
                    ]
                },
                {
                    header: 'Small background plates',
                    description: `
                        Some images will have very small background plates. Please be sure even plates shown through windows are marked. If there is a plate that is missed, please mark <code>No</code>.
                    `,
                    examples: [
                        {
                            description: 'Both the plate in the foreground and the background have been marked properly. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'validMarked.jpg'
                        },
                        {
                            description: 'The plate in the foreground is marked correctly, but the background plate was missed. This would be a <code>No</code>.',
                            positive: false,
                            image: 'missedPlates.jpg'
                        }
                    ]
                },
                {
                    header: 'Plates in Reflections',
                    description: `
                        Some images will have reflections of plates. If there is a plate that is marked that is a reflection, please mark <code>No</code>.
                    `,
                    examples: [
                        {
                            description: 'This image marked a reflection of a license plate. This would be a <code>No</code>.',
                            positive: false,
                            image: 'reflectionPlates.jpg'
                        }
                    ]
                },
                {
                    header: 'Tightly Marked Plates',
                    description: `
                        Please make sure the plates are tightly marked around the four corners. If there is a plate that is poorly marked, please mark <code>No</code>.
                    `,
                    examples: [
                        {
                            description: 'This image has poorly marked corners and missed background plates. This would be a <code>No</code>.',
                            positive: false,
                            image: 'looselyMarkedPlates.jpg'
                        },
                        {
                            description: 'This image has tightly marked corners. This would be a <code>Yes</code>.',
                            positive: true,
                            image: 'tightlyMarkedPlates.jpg'
                        }
                    ]
                }
            ]
        },
        person: {
            id: 23,
            header: 'Person confirmation',
            confirmation: 'Are all persons correctly marked?',
            workflow: [
                'Mark "Yes" (Up arrow) if all persons, regardless of size are well marked within the image.',
                'Mark "No" (Down arrow) if any persons are unmarked or have poor markings within the image.',
                'If unsure, skip the image using the "Skip" button or "s" key.',
                'Hit "Next" (spacebar and right arrow keys) to proceed to the next image.',
                `Things to look out for when marking "No":<ul>
                <li>Any person in the image that is improperly marked. Each person should have a relatively tight box placed around the majority of their body. If the box is not well placed, or a decent portion of the body is not within the box, then please select "No".</li>
                <li>Generally, if an image has many small persons in the background, lean towards marking "No".</li>
                <li>Additionally, if there is a crowded scene with overlapping persons that do not appear to be well marked, then please lean towards marking "No".</li>
                <li>Any small background persons are unmarked. Be very diligent in checking this on images with complex/large backgrounds. If there are small (even barely visible) unmarked persons in the background, please mark "No".</li>
                <li>Cartoon persons, statues, and animals are not considered persons, and if the image has any of these marked as a person please select "No".</li>
                </ul>`
            ]
        },
        vehicle: {
            id: 12,
            header: 'Vehicle confirmation',
            confirmation: 'Are all vehicles correctly marked?'
        }
    },
    gt_mixed_errors: {
        gt_mixed_errors: {
            id: 35,
            header: 'GT Study -- Mixed Errors',
        }
    },
    landmarking: {
        facial: {
            id: 10,
            header: 'Facial landmarks',
            image: 'facial-landmarks.gif',
            nPoints: 6,
            workflow: [
                'Mark the center most face in the image.',
                `Mark the following six points in this exact order on the face:<ol>
                <li>Left eye (person's right eye) center.</li>
                <li>Right eye (person's left eye) center.</li>
                <li>Nose tip</li>
                <li>Left mouth corner (person's right mouth corner).</li>
                <li>Right mouth corner (person's left mouth corner).</li>
                <li>Chin bottom.</li></ol>`,
                'Estimate missing/occluded locations to the best of your ability.',
                'Clear erroneous points with the <code>Undo</code> button or <code>u</code> key.'
            ]
        },
        iris: {
            id: 38,
            header: 'Iris landmarks',
            nPoints: 5,
            workflow: [
                `Mark the following points on the eye in this order: <ol>
                <li>The center of the pupil</li>
                <li>The left edge of the pupil</li>
                <li>The left edge of the iris</li>
                <li>The tear duct corner of the eye</li>
                <li>The other corner of the eye</li></ol>` 
            ],
            rules: [
                {
                    header: 'Examples of well annotated eyes:',
                    examples: [
                        {
                            description: 'This eye is well annotated. Notice how the edge of the iris is marked to the edge of the shadow of the iris.',
                            positive: true,
                            image: 'irisWellAnnotated.png'
                        },
                        {
                            description: 'This eye is well annotated. When you cannot see the corner of the eye, extrapolate where the corner would be. Look at how 4 and 5 were annotated off the image, where the corners would be.',
                            positive: true,
                            image: 'nonVisiblecornereye.png'
                        }

                    ]
                }
            ]
        },
        gun: {
            id: 20,
            header: 'Gun landmarks',
            crop: true,
            nPoints: 2,
            workflow: [
                'Mark the center-most gun in the image.',
                `Mark the following two points in order on the gun:<ol>
                <li>The top of the trigger area, or an estimation of where the top of the trigger area would be if occluded.</li>
                <li>The end of the barrel, or the farthest point along the barrel that is visible.</li></ol>`,
                'Skip images ("Skip" button or "s") without guns, or a gun that is mostly occluded/missing from the image.',
                'Hit the "Next" button (also "spacebar" or "right-arrow" keys) when you are finished marking the gun.'
            ]
        }
    },
    license_plate_labeling: {
        license_plate: {
            id: 19,
            header: 'License plates',
            description: `
                In this task, you will be presented an image of a cropped license plate, and you will be asked to determine
                the country and subregion/state of origin of the plate, as well as whether the plate number is legible and
                whether there are <a href="https://tagmasterna.com/tmna-ct-45-lpr-n-stacked-plates"/>stacked characters
                (one character on top of the other)</a> present.
            `,
            plates: LICENSE_PLATES,
            workflow: [
                'Select the appropriate license plate country from the drop down menu. If it is a legitimate license plate and you are unable to determine the country then please select <code>Unknown</code>. If it is a dealer or decorative plate (sports team, etc.), then please select <code>Dealer/Decorative</code>. If it is not a license plate then please select <code>Not a plate</code>. If you recognize the country but it is not in the list, then please select <code>Other</code>.',
                'If you selected a country above, please proceed to select the appropriate <code>Subregion</code>, if applicable.',
                'Check the <code>Unclear?</code> checkbox if the alphanumeric registration ID (the letters and numbers central to the plate) are not legible for any reason.',
                'Check the <code>Partial?</code> checkbox if any of the plate characters cannot be read or seen, it should be marked as partial with the characters that can be read typed out in the <code>Plate Number</code> section. (It could be occluded on the left, right, or middle)',
                'If the plate number has <a href="https://tagmasterna.com/tmna-ct-45-lpr-n-stacked-plates"/>stacked characters (one character on top of the other)</a>, then please check the <code>Stacked?</code> checkbox.',
                'Proceed to the next plate when you are finished labeling the current license plate.'
            ],
            rules: [
                {
                    header: 'Select <code>Dealer/Decorative</code> for unofficial plates from car dealerships or things such as sports teams.',
                    examples: [
                        {
                            description: 'This is a license plate from a car dealership, please mark <code>Dealer/Decorative</code> from the <code>Country</code> dropdown and move on to the next plate.',
                            image: 'dealer.jpg'
                        }
                    ]
                },
                {
                    header: 'Check <code>Unclear?</code> for plates that have their number covered',
                    description: `
                        If it is a license plate but the central plate number is covered, then please check
                        the <code>Unclear?</code> checkbox. If you can still make out the country and region,
                        then please mark the country and state accordingly.
                    `,
                    examples: [
                        {
                            description: 'This is a license plate from Washington, USA, but its plate number is obscured. Please mark the country and state correctly, and check the <code>Unclear?</code> checkbox.',
                            image: 'obscured1.jpg'
                        },
                        {
                            description: 'This is piece of paper over a license plate. You cannot tell the country/state, so leave those fields blank and check the <code>Unclear?</code> checkbox.',
                            image: 'obscured2.jpg'
                        }
                    ]
                }
            ]
        }
    },
    localization: {
        character: {
            id: 17,
            header: 'Characters',
            image: 'characters.gif',
            referenceSize: 12,
            downScale: 1.3,
            workflow: [
                'Skip images with no visible/legible characters by hitting the "Skip" button in the bottom left corner.',
                'Characters are any letter, or symbol that you can type on a standard keyboard ("A", "1", "-", "." all are valid characters)',
                'Mark all four corners of the character, clockwise, starting with the top left corner, as tightly as you can.',
                'Each character should get its own box and all characters should be marked. Please be extra careful to not miss any characters!',
                'In some cases, characters will be too small and/or too blurry to mark out character boundaries. In this case, mark the 4 corners of the entire word or character group. Your box should encompass all of the blurry characters in that section.',
                'Use the moveable size reference box as a guide for when characters get too small. If characters are smaller than the box, but still legible and clearly distinguishable from their neighbors, please mark each character individually as normal. Similarly, if characters are slightly bigger than the box, but so blurry that you can\'t distinguish individual letters please mark the entire word.',
                'Mark the edge of the image in cases where the character extends past the edge.',
                'Estimate corner locations in cases where a corner is obscured.',
                'Mark all characters in the image.',
                'Hit the "Next" button (also "spacebar" or "right-arrow" keys) when you are finished marking characters for a given image.',
                'Clear erroneous markings with the "Undo" button or "u" key.'
            ]
        },
        face_detection: {
            id: 27,
            header: 'Face localization',
            description: 'Box all recognizable faces in the image',
            image: 'face_localization.gif',
            workflow: [
                'Box all recognizable faces in the image. For <code>recognizable</code>, think if it was a person you knew well, would you be able to recognize them in the image? If there are any faces that you can not recognize (small, blurry, etc.), then please skip the image.',
                'If there are no faces, then you can skip the image. If you\'re unsure on how to box a particular face then please ask questions and/or skip the image.',
                'Do not box artwork (cartoons, statues, paintings, etc.) as faces.',
                'Do not box faces that have neither eyes visible (i.e. the back of the head). If the face is rotated beyond about 90-100 degrees then please do not mark it.',
                'To box a face, click and drag from the top-left corner to the bottom-right corner of a face. Make sure to always start at the top-left of the image and drag towards the bottom-right.',
                'Try to tightly box from ear-to-ear width wise, and forehead-to-chin height wise. For profile/side-view faces, please make sure to include the nose tip in the box.',
                'Estimate occluded faces to the best of your ability.',
                'Zoom in as necessary to accurately box small faces.',
                'Clear erroneous boxes with the <code>Undo</code> button or <code>u</code> key.',
                'Look out for small faces in the background, and make sure to box them to the best of your ability if they are <code>recognizable</code>. If an image has many small faces and/or a crowd (more than ten or so faces), then please skip the image.',
                'Proceed to the next task once all faces are boxed.'
            ]
        },
        gun: {
            id: 11,
            header: 'Gun Localization',
            description: `
                In this task, you will be asked to mark all guns, if there are any, within a presented image.
                Guns include any typical firearms (pistols, rifles, shotguns, etc.), and do not include
                things such as tasers, paintball guns, animated/CGI guns, and other things of that nature.
                If there are no valid guns, then please skip the image, otherwise, once you have marked all guns
                then please proceed to the next image.
            `,
            workflow: [
                'Skip images with no guns using the <code>Skip</code> button or <code>s</code> key.',
                'Mark all four corners of the gun, clockwise, starting with the top left corner, as tightly as you can.',
                'Mark the edge of the image in cases where the gun extends past the edge.',
                'Estimate corner locations in cases where a corner is obscured.',
                'Mark all guns in the image. Animated/cartoon guns, tasers, and paintball guns do not count as guns and should not be marked.',
                'Clear erroneous points with the <code>Undo</code> button or <code>u</code> key.',
                'Proceed to the next image once all guns are marked using the <code>Next</code> button, <code>Spacebar</code> or <code>right-arrow</code> keys.'
            ],
            rules: [
                {
                    header: 'Skip images with no valid guns or small/blurry guns',
                    description: `
                        If an image does not contain a valid gun, then please skip it with the <code>Skip</code>
                        button or <code>s</code> key. If the image contains hard to make out (small, blurry, very occluded)
                        guns then please elect to skip the image as well.
                    `,
                    examples: [
                        {
                            description: 'Although the foreground gun is fine, this image contains small, blurry background guns that we would like to avoid. Please skip images such as these.',
                            positive: false,
                            image: 'blurry.jpg'
                        },
                        {
                            description: 'This gun is too occluded, please skip when there is less than half of the gun visible.',
                            positive: false,
                            image: 'partial.jpg'
                        }
                    ]
                },
                {
                    header: 'Skip images with many guns and/or overlapping guns',
                    description: `
                        If the image contains more than 5 guns, or guns that overlap then please skip the image.
                    `,
                    examples: [
                        {
                            description: 'This image contains both too many guns, as well as overlapping guns.',
                            positive: false,
                            image: 'overlapping.jpg'
                        }
                    ]
                },
                {
                    header: 'Skip invalid images',
                    description: `
                        If an image is clearly a cartoon, video game or computer generated, then please
                        skip the image. Additionally, please skip images that are collages, meaning there
                        are multiple images contained within the image.
                    `,
                    examples: [
                        {
                            description: 'This image is multiple images stiched together, i.e. a collage. Please skip these.',
                            positive: false,
                            image: 'collage.jpg'
                        },
                        {
                            description: 'This image contains a border from a source such as a local news station. Please skip these.',
                            positive: false,
                            image: 'border.jpg'
                        },
                        {
                            description: 'This is a computer generated image from a video game, please skip.',
                            positive: false,
                            image: 'cgi.jpg'
                        },
                        {
                            description: 'This image contains a logo in the top right corner, please skip.',
                            positive: false,
                            image: 'logo.jpg'
                        }
                    ]
                },
                {
                    header: 'Mark all valid guns in the image',
                    description: `
                        A valid gun is a firearm such as a pistol, rifle, shotgun, etc.. Invalid guns include
                        stun guns (tasers), paintball guns, animated/computer generated guns, gun logos,
                        and anything else you would typically not consider a "real" gun.
                    `,
                    examples: [
                        {
                            description: 'This image contains two guns that are both properly marked.',
                            positive: true,
                            image: 'well-marked.jpg'
                        },
                        {
                            description: 'This image contains two guns, with only one marked. Please take care not to miss any guns!',
                            positive: false,
                            image: 'poorly-marked.jpg'
                        }
                    ]
                },
                {
                    header: 'Mark the four corners of each gun',
                    description: `
                        Mark all four corners of the gun, clockwise, starting with the top left corner
                        as tightly as you can. Mark near the edge of the image in cases where the gun extends
                        past the edge. Estimate corner locations in cases where a corner is obscured.
                    `,
                    examples: [
                        {
                            description: 'The four corners of this gun are tightly marked.',
                            positive: true,
                            image: 'tight-mark.jpg'
                        },
                        {
                            description: 'The four corners of this gun are too loosely marked.',
                            positive: false,
                            image: 'loose-mark.jpg'
                        }
                    ]
                },
                {
                    header: 'Mark holstered guns',
                    description: `
                        Take care to mark holstered guns that you would typically find holstered on the hips
                        of persons such as police officers and miltary personnel.
                    `,
                    examples: [
                        {
                            description: 'This holstered gun is properly marked. Please make sure not to miss holsterd guns!',
                            positive: true,
                            image: 'holstered.jpg'
                        }
                    ]
                }
            ]
        },
        license_plate: {
            id: 1,
            header: 'License plates',
            image: 'license-plate.gif',
            workflow: [
                'Skip images with no license plates.',
                'Mark all four corners of the license plate, clockwise, starting with the top left corner.',
                'Mark the license plate as tightly as you can.',
                'Mark the edge of the image in cases where the license plate extends past the edge.',
                'Estimate corner locations in cases where a corner is obscured.',
                'Clear erroneous points with the "Undo" button or <code>u</code> key.',
                'Mark all license plates in the image.',
                'Mark dealer/advertisement plates as license plates.',
                'Mark the visible corners/edges of the plate, that is, mark the plate itself and not things such as license plate holders.'
            ]
        },
        logo: {
            id: 3,
            header: 'Logos',
            description: `
                In this task you will be asked to box logos on pedestrians and vehicles.
                A logo is a graphic symbol or other design adopted by an organization to
                identify its products, uniform, vehicles, or other associated material.
                Common categories are flags, sports insignias, insignias on clothing,
                vehicle brands, vehicle bumper stickers/decals, political insignias,
                military insignias, and other brands emblems. Think of any stylized
                text of graphic that is meant to easily be re-identifiable.
            `,
            workflow: [
                'Skip images that do not contain a logo either on a pedestrian or vehicle.',
                'If a pedestrian and/or vehicle contains a logo, then please mark said logo, as well as <em>all</em> other background logos in the image.',
                'Pay careful attention to mark all logos in the image once you have begun marking any logo.',
                'To box a logo, click and drag from the top-left corner to the bottom-right corner of a logo. Make sure to always start at the top-left of the image and drag towards the bottom-right.',
                'Be liberal with what you mark as a logo. If you think something is too small/blurry, please try to mark it anyways and we can filter these out if necessary later on.'
            ],
            rules: [
                {
                    header: 'Skip images that do not contain logos on pedestrians or vehicles'
                },
                {
                    header: 'Mark logos on pedestrians and vehicles',
                    description: `
                        If an image contains a pedestrian and/or vehicle with a visible logo,
                        please box all logos on the pedestrians and/or vehicles, as well as any logos that appear in the background.
                    `,
                    examples: [
                        {
                            description: `
                                The BMW logos on the vehicle are all marked. Additionally all background logos are marked to
                                include <code>Circle K</code>, <code>AdBlue</code>, and <code>DSV</code> on the truck in the background.
                            `,
                            positive: true,
                            image: 'vehicle-background.jpg'
                        }
                    ]
                }
            ]
        },
        person: {
            id: 5,
            header: 'Person Localization',
            description: `
                In this task, you will be asked to mark all people, if there are any, within a presented image.
                People do not include animated/CGI people, and other things of that nature.
                If there are no valid people, then please skip the image, otherwise, once you have marked all people
                then please proceed to the next image. You will click and drag a box to cover the whole person.
                `,
            workflow: [
                'Skip images with no people using the <code>Skip</code> button or <code>s</code> key.',
                'Drag the box around the person, starting with the top left corner, as tightly as you can.',
                'Mark the edge of the image in cases where the person extends past the edge.',
                'Mark all people in the image. Animated/cartoon people do not count as people and should not be marked.',
                'Clear erroneous points with the <code>Undo</code> button or <code>u</code> key.',
                'Proceed to the next image once all people are marked using the <code>Next</code> button, <code>Spacebar</code> or <code>right-arrow</code> keys.',
                `<li>Only box the visible portions of people. The only caveat is that narrow obstructions such as poles that intersect a person do not count and we would like these people included in one box. </li>
                <li>Background groups of people can be included in a single "group" box. If you have questions on this then please feel free to ask them. </li>
                <li>Please avoid skipping any images that have people in this exercise. Normally we want you to skip odd cases, but we want everything marked up here. If there is a person, no matter how small or blurry please try to box them. You can box small blurry people in the background as groups if possible, but please keep it tight. We are trying to identify "areas of interest" in these images, which are people in this case, so we want them boxed but not the environment around them as much as possible. </li>
                <li>For people in the foreground, please try to box them individually unless it is extremely burdensome to do so. Otherwise you can box them as a group. </li>`
            ],
            rules: [
                {
                    header: 'Do not mark advertisements or CGI Images.',
                    description: `
                        Be sure to mark all people in the image, but please skip any image that is a collage, contains a clearly CGI
                        person, or the background image is blurry. Please also do not mark people who appear in advertisements.
                    `,
                    examples: [
                        {
                            description: 'This image is multiple images stiched together, i.e. a collage. Please skip these.',
                            positive: false,
                            image: 'collage-person.jpg'
                        },
                        {
                            description: 'This is a CGI image of a person. Do not mark this.',
                            positive: false,
                            image: 'cgi-person.jpg'
                        }
                    ]
                },
                {
                    header: 'Be sure to closely box each person in the image.',
                    description: `
                        Adjust the boxes around the person as necessary, and as tightly as you can.
                        Mark near the edge of the image in cases where the person extends
                        past the edge.
                    `,
                    examples: [
                        {
                            description: 'This person is poorly boxed because the box is too loose.',
                            positive: false,
                            image: 'poorly-boxed-person.jpg'
                        },
                        {
                            description: 'This image is well marked up.',
                            positive: true,
                            image: 'wellMarked.jpg'
                        }
                    ]
                }
            ]
        },
        vehicle: {
            id: 6,
            header: 'Vehicle localization',
            description: `
                In this task, you will be asked to mark all vehicles, if there are any, within a presented image.
                A vehicle includes any car, bus, motorcycle or truck.
                Vehicles do not include animated/CGI vehicles, and other things of that nature and please do not mark any non-land vehicles
                (e.g. planes and boats).
                If there are no valid vehicles, then please skip the image, otherwise, once you have marked all vehicles
                then please proceed to the next image. You will click and drag a box to cover the whole vehicle.
            `,
            workflow: [
                'Skip images with no vehicles using the <code>Skip</code> button or <code>s</code> key.',
                'Drag the box around the vehicle, starting with the top left corner, as tightly as you can.',
                'Mark the edge of the image in cases where the vehicle extends past the edge.',
                'Mark all vehicles in the image. Animated/cartoon vehicles do not count as people and should not be marked.',
                'Do not mark any non-land vehicles (e.g. planes and boats)',
                'Clear erroneous points with the <code>Undo</code> button or <code>u</code> key.',
                'Proceed to the next image once all vehicles are marked using the <code>Next</code> button, <code>Spacebar</code> or <code>right-arrow</code> keys.',
                `<li>Only box the visible portions of vehicles. The only caveat is that narrow obstructions such as poles that intersect a vehicle do not count and we would like these vehicles included in one box. </li>
                <li>Background groups of vehicles can be included in a single "group" box. If you have questions on this then please feel free to ask them. </li>
                <li>Please avoid skipping any images that have vehicles in this exercise. Normally we want you to skip odd cases, but we want everything marked up here. If there is a vehicle, no matter how small or blurry please try to box them. You can box small blurry vehicles in the background as groups if possible, but please keep it tight. We are trying to identify "areas of interest" in these images, which are vehicles in this case, so we want them boxed but not the environment around them as much as possible. </li>
                <li>For vehicles in the foreground, please try to box them individually unless it is extremely burdensome to do so. Otherwise you can box them as a group. </li>`
            ],
            rules: [
                {
                    header: 'Do not mark advertisements or CGI Images.',
                    description: `
                        Be sure to mark all vehicles in the image, but please skip any image that is a collage, contains a clearly CGI
                        person, or the background image is blurry. Please also do not mark vehicles which appear in advertisements.
                    `
                },
                {
                    header: 'Be sure to closely box each vehicle in the image.',
                    description: `
                        Adjust the boxes around the vehicles as necessary, and as tightly as you can.
                        Mark near the edge of the image in cases where the vehicle extends
                        past the edge.
                    `,
                    examples: [
                        {
                            description: 'This image has been marked up well.',
                            positive: true,
                            image: 'wellMarked.jpg'
                        },
                        {
                            description: 'This image has been marked up well..',
                            positive: true,
                            image: 'wellMarked2.jpg'
                        }
                    ]
                }
            ]
        }
    },
    localization_update: {
        license_plate: {
            id: 32,
            header: 'License Plate localization',
            description: `
                In this task, you will be presented an image with a license plate (or plates) and asked to make sure all license plates are tightly boxed.
                You are able to adjust existing boxes, delete incorrect boxes, and draw any missing boxes as needed.
                After you are satisfied with the accuracy of your boxes, you will need to label each with one of the following labels:

                <ol type="1">
                    <li> A fully visible license plate. This is the default label. <i>(green)</i> </li>
                    <li> A partially visible license plate <i>(blue)</i> </li>
                    <li> An "ignore" region <i>(red)</i> </li>
                </ol>
        `,
            workflow: [`
                <strong> Localization: </strong>

                <ul>
                    <li> If an existing license plate box is too loose, please adjust it by clicking and dragging its corner points. </li>
                    <li> If there is a box around a non-license plate, please delete the box entirely by clicking on a corner of the box and then pressing <code>d</code> key. </li>
                    <li> If there is a license plate with no box around it, please draw the missing box by clicking on the four corners of the plate. </li>
                    <li> If there is an area of the image that could be confused for a license plate or that you are unsure of, please draw a box around it and label it as an "ignore" region in the subsequent step. \
                         Ignore regions should be tightly bound around the area or object of interest, not mass regions. </li>
                </ul>
                `,
                `
                <strong> Labeling: </strong>

                For each of your finalized license plate boxes, please click the corner and label using the <code>1</code>, <code>2</code>, or <code>3</code> keys on your keyboard.

                <ol type="1">
                    <li> <b>Green:</b> a fully visible license plate with no occlusion or truncation. This is the default label. You can reset it with the <code>1</code> key if you change to another label and later want to change back. </li>
                    <li> <b>Blue:</b> a partially visible license plate. </li>
                    <li> <b>Red:</b> an ignore region </li>
                </ol>

                When you are finished localizing and labeling the license plates, please use the <code>Next</code> button, <code>spacebar</code> or <code>right-arrow</code> key to proceed to next image.
            `],
            rules: [
                {
                    header: 'Examples of well annotated images',
                    description: `These images have been well annotated and represent excellent final products.
                    `,
                    examples: [
                        {
                            description: 'This image has been well annotated. The center license plate is fully visible (green) and the right license plate has been marked as an "ignore" region (red) because it has been digitally altered.',
                            positive: true,
                            image: 'GoodOverall1.jpg'
                        },
                        {
                            description: 'This image has been well annotated. The lower license plate is fully visible (green) and the upper license plate has been marked as an "ignore" region (red) because it has been digitally altered.',
                            positive: true,
                            image: 'GoodOverall2.jpg'
                        },
                        {
                            description: 'This image has been well annotated. The two leftmost license plates are fully visible (green) and the license plate extending off the right edge of image has been correctly labeled as partially visible (blue).',
                            positive: true,
                            image: 'GoodOverall3.jpg'
                        }
                    ]
                },
                {
                    header: 'Bounding box adjustments',
                    description: `Bounding boxes should be tight around the license plates, with the 4 corners of a box drawn on top of the 4 corners of the license plate.
                    `,
                    examples: [
                        {
                            description: 'The two license plates are tightly and accurately boxed. No changes needed',
                            positive: true,
                            image: 'fullyLegibleExample.jpg'
                        },
                        {
                            description: 'This image has a poorly drawn box. The corners of the box need to be adjusted so that it is tight and accurate.',
                            positive: false,
                            image: 'badLocalization.jpg'
                        },
                    ]

                },
                {
                    header: 'Ignore region',
                    description: `If there is an area of the image that could be confused for a license plate or that you are unsure of, please draw a box around it and label it as an "ignore" region.' Some images have been purposefully blurred/blacked
                        out to obscure a license plate. Please mark these license plates with an "ignore" region.
                    `,
                    examples: [
                        {
                            description: 'This is a correct use of an "ignore" region box. It contains a logo that looks like an actual license plate, but is not real. No changes are needed.',
                            positive: true,
                            image: 'ignoreBoxCorrect.jpg'
                        },
                        {
                            description: 'This is an incorrect use of an "ignore" region. Ignore regions should be tightly boxed around the area of interest. You should not box large background areas as "ignore."' ,
                            positive: false,
                            image: 'ignoreBoxIncorrect.png'
                        }
                    ]

                },
                {
                    header: 'Background license plates',
                    description: `
                        If you know that there is a license plate, it should be boxed, no matter how blurry or small. Some tricky cases include very small background license plates and plates visible through the window of another vehicle.
                    `,
                    examples: [
                        {
                            description: 'All license plates in the foreground and background have been marked properly. Zoom in and look carefully at the license plate visible through the windshields of the vehicle behind it. No changes are needed.',
                            positive: true,
                            image: 'validSmallBackgroundWindow.jpeg'
                        },
                        {
                            description: 'The license plate in the foreground is marked correctly, but the background plate was missed. The background plate is fully visible, so it should be marked with a green box.',
                            positive: false,
                            image: 'missedPlates.jpg'
                        },

                    ]
                },
                {
                    header: 'Partially visible license plates',
                    description: `
                        Please make sure the visible part of any partially visible license plates is tightly boxed. You should try to not extend your box past the edge of the image.
                    `,
                    examples: [
                        {
                            description: 'This is a partial plate that has been correctly annotated with a blue box. No changes are needed.',
                            positive: true,
                            image: 'partialPlate.jpg'
                        },
                        {
                            description: 'Both the center plate and partially visible plate are marked correctly. The partial plate extends further to the right, but the box ends at the edge of the image. No changes are needed.',
                            positive: true,
                            image: 'partialAdam.jpg'
                        }
                    ]
                },
                {
                    header: 'False positives and other boxes requiring deletion',
                    description: `
                        Sometimes you will encounter a box that is not actually a license plate, which we refer to as a "false positive". Please select one of the corners and press the <code>d</code> key to delete the box.
                    `,
                    examples: [
                        {
                            description: 'The box on the back of the RV is not a license plate and must be deleted.',
                            positive: false,
                            image: 'falsePositive1.jpg'
                        },
                        {
                            description: 'The two boxes on the gas pump are not license plates and must be deleted.',
                            positive: false,
                            image: 'falsePositive2.jpg'
                        },
                        {
                            description: 'The box around "Muzzy" on the muffler is not a license plate and must be deleted.',
                            positive: false,
                            image: 'false-positive-example.png'
                        },
                        {
                            description: 'The false positive in previous example has been deleted. No other changes are needed.',
                            positive: true,
                            image: 'false-positive-example-fixed.png'
                        },
                        {
                            description: 'Some license plates may be double boxed. Please delete one of the boxes and adjust the one remaining as needed.',
                            positive: false,
                            image: 'doubleBox.jpg'
                        }

                    ]
                }
            ]
        },
        gun: {
            id: 37,
            header: 'Gun localization',
            description: `
                In this task, you will be presented an image with a gun (or guns) and asked to make sure all guns are tightly boxed.
                You are able to adjust existing boxes, delete incorrect boxes, and draw any missing boxes as needed.
            `,
            workflow: [`
                <strong> Localization: </strong>

                <ul>
                    <li> If an existing gun box is too loose, please adjust it by clicking and dragging its corner points. </li>
                    <li> If there is a box around a non-gun, please delete the box entirely by clicking on a corner of the box and then pressing <code>d</code> key. </li>
                    <li> If there is a gun with no box around it, please draw the missing box by clicking on the top left corner and dragging a box around the gun tightly. </li>
                    <li> If there is an area of the image that could be confused for a gun or that you are unsure of, please draw a box around it and label it as an "ignore" region in the subsequent step. \
                         Ignore regions should be tightly bound around the area or object of interest, not mass regions. </li>
                </ul>
                `,
                `
                <strong> Labeling: </strong>

                For each of your finalized gun boxes, please click the corner and label using the <code>1</code> or <code>i</code> keys on your keyboard.

                <ol type="1">
                    <li> <b>Green:</b> This is the default label. You can reset it with the <code>1</code> key if you change to another label and later want to change back. </li>
                    <li> <b>Red:</b> an ignore region </li>
                </ol>

                When you are finished localizing and labeling the guns, please use the <code>Next</code> button, <code>spacebar</code> or <code>right-arrow</code> key to proceed to next image.
            `],
            rules: [
                {
                    header: 'Well Marked Gun',
                    description:
                    `
                        Be sure to have tight boxes around the guns annotated.
                    `,
                    examples: [
                        {
                            description: 'This image shows two guns well marked.',
                            positive: true,
                            image: 'wellMarked.jpg'
                        }
                    ]
                },
                {
                    header: 'Bounding Box Adjustments',
                    description: `
                        When redrawing the box, make sure all four corneres are as tightly around the gun as you can.
                        Mark near the edge of the image in cases where the gun extends
                        past the edge.
                    `,
                    examples: [
                        {
                            description: 'The four corners of this gun are too loosely marked.',
                            positive: false,
                            image: 'loose-mark1.jpg'
                        },
                        {
                            description: "This has fixed the image to be a tightly marked box.",
                            positive: true,
                            image: 'tight-mark1.jpg'
                        }
                    ]
                },
                {
                    header: 'Include gun components',
                    description: `
                        Take care to make sure that the box includes the gun's scope, bipod, and ammo magazines if it exists.
                        Scopes mostly appear on long guns, but rarely are included on short guns, so please
                        make sure to pay close attention to include all components of the gun.
                    `,
                    examples: [
                        {
                            description: 'This scoped gun is properly marked. Please make sure not to miss scopes!',
                            positive: true,
                            image: 'scopes.png'
                        },
                        {
                            description: 'This bipod attached to the gun is properly marked.',
                            positive: true,
                            image: 'bipod.png'
                        },
                        {
                            description: 'This ammo magazine attached to the gun is properly marked.',
                            positive: true,
                            image: 'ammoMag.png'
                        }
                    ]
                },
                {
                    header: 'Delete False Boxes',
                    description: `
                        Any box that is around not a gun (i.e. a false positive), please delete using the "d" key.
                    `,
                    examples: [
                        {
                            description: "This box is around a woman's hair. Please delete the box.",
                            positive: false,
                            image: 'falsePositiveShouldDelete.jpg'
                        },
                        {
                            description: "This is a fixed image showing the box around the woman's hair deleted.",
                            positive: true,
                            image: 'falsePositiveFixed.jpg'
                        }
                    ]
                },
                {
                    header: 'Be sure to box any missed guns',
                    descriptions:  `
                        Any image that has a gun that has been missed should be thouroughly marked up.
                    `,
                    examples: [
                        {
                            description: "This has a missed gun that should be boxed. Holstered guns should be annotated as well",
                            positive: false,
                            image: 'falseNegative.jpg'
                        },
                        {
                            description: "This image has been marked up to box the missed gun.",
                            positive: true,
                            image: 'falseNegativeMarked.jpg'
                        },
                        {
                            description: "This has a missed gun that should be boxed. The gun on the left is well boxed.",
                            positive: false,
                            image: 'unmarked.png'
                        },
                        {
                            description: "This image has been marked up to box the missed gun.",
                            positive: true,
                            image: 'unmarkedFixed.png'
                        }
                    ]
                },
                {
                    header: 'Truncation and Occlusion',
                    description: `
                        If a gun goes off the edge of the image, mark the gun to the edge of the image, but do not try to extend the box off the side.
                        If a gun is partially occluded, mark the visual portion. This means if you can only see the handle of the gun, only mark that.
                        If something is partially occluding the gun, mark all visual portions of the gun i.e. if someones hand is covering part of the handle, but you can see the top of the gun and bottom of the handle, mark the whole gun.
                    `,
                    examples: [
                        {
                            description: 'This gun is cutoff at the edge of the image. Be sure to annotate to just the edge of the image',
                            positive: true,
                            image: 'truncatedGun.png'
                        },
                        {
                            description: 'This gun is partially occluded, be sure to mark all visable portions',
                            positive: true,
                            image: 'occludedGun.png'
                        },
                        {
                            description: 'This gun is occluded, be sure to only mark the visable portion',
                            positive: true,
                            image: 'occludedGun2.png'
                        }
                    ]
                },
                {
                    header: 'Billboards, posters, and artwork',
                    description: `
                        Be sure to mark photo realistic guns, but not artwork of guns.
                    `,
                    examples: [
                        {
                            description: 'This billboard has a photo realistic image of a gun, so the gun should be boxed.',
                            positive: true,
                            image: 'gunBillboard.png'
                        },
                        {
                            description: 'Artwork of guns should not be boxed',
                            positive: true,
                            image: 'gunArt.png'
                        }
                    ]
                },
                {
                    header: 'Do not mark non-guns',
                    description: `
                        Any box that is around not a gun (i.e. a false positive), please delete using the "d" key. Tank turrets, tasers, cannons, and other large weapons that aren't a regular firearms.
                    `,
                    examples: [
                        {
                            description: "This tank is not a gun and should NOT be marked.",
                            positive: true,
                            image: 'tank.png'
                        },
                        {
                            description: "Tasers are not guns and should NOT be marked.",
                            positive: true,
                            image: 'taser.png'
                        },
                        {
                            description: "Cannons are not guns and should NOT be marked.",
                            positive: true,
                            image: 'canon.png'
                        },
                        {
                            description: "Paintball guns are not guns and should NOT be marked.",
                            positive: true,
                            image: 'paintball.png'
                        }
                    ]
                },
                {
                    header: 'Ignore region',
                    description: `If there is an area of the image that could be confused for a gun or that you are unsure of, please draw a box around it and label it as an "ignore" region.
                    `,
                    examples: [
                        {
                            description: 'This is a correct use of an "ignore" region box. It contains a part that looks like it might be a holstered gun, but I am not positive it is a holstered gun and not a taser. No changes are needed.',
                            positive: true,
                            image: 'ignoreGun.png'
                        },
                        {
                            description: 'This is an incorrect use of an "ignore" region. Ignore regions should be tightly boxed around the area of interest. You should not box large background areas as "ignore."' ,
                            positive: false,
                            image: 'ignoreGunWrong.png'
                        }
                    ]

                }
            ]
        },
        person: {
            id: 46,
            header: 'Person localization',
            description: `
                In this task, you will be presented an image with pre-boxed people, and are asked to adjust boxes that are not tightly marked
                around a person, delete boxes that are not around a person and draw new boxes around unboxed people.
            `,
            workflow: [`
                <strong> Localization: </strong>
                <ul>
                    <li> If an existing person box is too loose, please adjust it by clicking and dragging its corner points. </li>
                    <li> If there is a box around a non-person, please delete the box entirely by clicking on a corner of the box and then pressing <code>d</code> key. </li>
                    <li> If there is a person with no box around it, please draw the missing box by clicking on the top left corner and dragging a box around the person. </li>
                    <li> If there is an area of the image that could be confused for a person or that you are unsure of, please draw a box around it and label it as an "ignore" region in the subsequent step. \
                        Ignore regions should be tightly bound around the area or object of interest, not mass regions. </li>
                </ul>
                `,
                `
                <strong> Labeling: </strong>
                For each of your finalized person boxes, please click the corner and label using the <code>1</code> (green), <code>i</code> (ignore), or <code>o</code> (person has object) keys on your keyboard.
                <ol type="1">
                    <li> <b>Green:</b> This is the default label. You can reset it with the <code>1</code> key if you change to another label and later want to change back. </li>
                    <li> <b>Red:</b> an ignore region </li>
                    <li> <b>Purple:</b> Person is holding/ wearing an object.
                </ol>
            `,
            `
            <strong> Person with Object: </strong>
            <ol type="1">
                <li> If they have any object in their hand or are carrying an object on their person- e.g. shoulder bag, purse, backpack etc. </li>
                <li> If they are wearing anything on their head- e.g. a hat, headscarf, helmet etc. This does not include hoods from a jacket or a sweatshirt, even if they are pulled up. </li>
                <li> If anything is visible on their face or neck- e.g. a face mask, scarf, lanyard, sunglasses, glasses or large necklace </li>
                <li> If anything is clearly visible on their wrist- e.g. a watch or bracelet </li>
                <li> If they are interacting with a vehicle. e.g. riding a bike or motorcycle, riding a scooter, opening a car door (their hand is in contact with the handle, not just nearby), holding a steering wheel etc </li>
             </ol>
            When you are finished localizing and labeling the people, please use the <code>Next</code> button, <code>spacebar</code> or <code>right-arrow</code> key to proceed to next image.
            If you are unsure about an image for any reason, please press <code>Skip</code>.
            `
        ],
            rules: [
                {
                    header: 'Be sure to closely box each person in the image.',
                    description: `
                        Adjust the boxes around the person as necessary, and as tightly as you can.
                        Mark near the edge of the image in cases where the person extends
                        past the edge.
                    `,
                    examples: [
                        {
                            description: 'This person is poorly boxed because the box is too loose. He is also carrying something and wearing sunglasses, headphones, and a scarf, so he should be boxed in purple. ',
                            positive: false,
                            image: 'poorly-boxed-person.jpg'
                        },
                        {
                            description: 'This person is well boxed. They have been marked as having an object of interest because they are wearing a bike helmet.',
                            positive: true,
                            image: 'wellMarked.png'
                        },
                        {
                            description: 'This person is poorly boxed because the box is too loose. The woman is also wearing a scarf and carrying a purse which would mean she has an object on her, so she should be marked purple.',
                            positive: false,
                            image: 'badBox.png'
                        },
                        {
                            description: 'Both of the women have been boxed properly, however the woman on the left should have a purple box because of her purse she is wearing and the scarf she has on.',
                            positive: false,
                            image: 'wellBoxed2.png'
                        },

                    ]
                },
                {
                    header: 'Be sure to mark people who are holding or wearing an object beyond typical clothes (shirt, pants, jacket, shoes).',
                    description: `
                        If they have any object in their hand or are carrying an object on their person, are wearing anything on their head, anything is visible on their face or neck, they are interacting with a vehicle, or anything is clearly visible on their wrist.
                    `,
                    examples: [
                        {
                            description: 'This person is wearing sunglasses. She is well marked and is marked as having an object of interest (sunglasses). She is also driving a car meaning she is interacting with a vehicle, so the woman should be boxed in purple.',
                            positive: true,
                            image: 'personSunglasses.png'
                        },
                        {
                            description: 'This person is wearing a watch and a bracelet, riding a bike, and holding something in her right hand. This woman is well marked and is marked as having an object of interest',
                            positive: true,
                            image: 'watch.png'
                        },
                        {
                            description: 'This image is well marked for each person. Each person has an object on them (top left: holding roll of paper, top right: holding cell phone and roll of paper, bottom left: wearing a backpack, bottom center left: wearing red hat and holding bag, bottom center right: holding shopping bags and purse, bottom right: holding backpack and cigarettes',
                            positive: true,
                            image: 'busy.png'
                        },
                        {
                            description: 'These people are all not wearing accessories and do not have any items they are holding. These people would NOT be marked as people holding an object of interest and should be left with a green box.',
                            positive: true,
                            image: 'busyNoObject.png'
                        },
                    ]
                },
                {
                    header: 'Be sure to mark partial body parts as people.',
                    description: `
                        Partial people should still be marked as people.
                    `,
                    examples: [
                        {
                            description: 'These people are well marked. Notice how the leg is still recognizable as a person, so it should be marked. The woman who can be fully seen is wearing a bracelet on her wrist meaning she should be boxed in purple.',
                            positive: true,
                            image: 'partial.png'
                        },
                        {
                            description: 'These people are well marked. Notice how the legs and hands of the person on the right are still recognizable as a person, so this should be marked as a person.',
                            positive: true,
                            image: 'partial2.png'
                        },
                    ]
                },
                {
                    header: 'Be sure to mark realistic billboards and reflections as people.',
                    description: `
                        Anything that is not a cartoon of a person should be marked as a person.
                    `,
                    examples: [
                        {
                            description: 'These people are well marked. Notice how the person on the billboard is still marked as a person.',
                            positive: true,
                            image: 'billboard.png'
                        },
                        {
                            description: 'These are mannequins which should not be considered a person.',
                            positive: true,
                            image: 'mannequins.png'
                        },
                        {
                            description: 'This is artwork of a person, and should not be considered a person.',
                            positive: true,
                            image: 'artwork.png'
                        }
                    ]
                }

            ]
        },
        vehicle: {
            id: 47,
            header: 'Land vehicle localization',
            description: `
                In this task, you will be presented an image with pre-boxed land-vehicle(s), and are asked to adjust boxes that are not tightly marked
                around a vehicle, delete boxes that are not around a vehicle and draw new boxes around unboxed vehicles.
            `,
            workflow: [`
                <strong> Localization: </strong>
                <ul>
                    <li> If an existing vehicle box is too loose, please adjust it by clicking and dragging its corner points. </li>
                    <li> A vehicle is any kind of on or off-road motor vehicle that has wheels or tracks, scooters, skateboards, bicycles, wheelchairs, strollers, trailers, garden carts, shopping carts, food carts, rickshaws, and horse drawn carriages. </li>
                    <li> Do NOT box planes and boats (ie non-land vehicles). Trailers carrying boats/planes should be annotated </li>
                    <li> Any other object with wheels should be marked as an ignore region</li>
                    <li> If there is a box around a non-vehicle, please delete the box entirely by clicking on a corner of the box and then pressing <code>d</code> key. </li>
                    <li> If there is a vehicle with no box around it, please draw the missing box by clicking and dragging a box around the vehicle. </li>
                    <li> If there is an area of the image that could be confused for a vehicle or that you are unsure of, please draw a box around it and label it as an "ignore" region in the subsequent step. \
                        Ignore regions should be tightly bound around the area or object of interest, not mass regions. </li>
                </ul>
                `,
                `
                <strong> Labeling: </strong>
                For each of your finalized vehicle boxes, please click the corner and label using the <code>1</code> (green) or <code>i</code> (red) keys on your keyboard.
                <ol type="1">
                    <li> <b>Green:</b> This is the default label. You can reset it with the <code>1</code> key if you change to another label and later want to change back. </li>
                    <li> <b>Red:</b> an ignore region </li>
                </ol>
                If you are unsure about an image for any reason, please press <code>Skip</code>. If you need to press skip more than twice, please email data@roc.ai to ask about the type of image you are skipping.
                When you are finished localizing and labeling the vehicles, please use the <code>Next</code> button, <code>spacebar</code> or <code>right-arrow</code> key to proceed to next image.
            `],
            rules: [
                {
                    header: 'Be sure to closely box each vehicle in the image.',
                    description: `
                        Adjust the boxes around the vehicles as necessary, and as tightly as you can.
                        Mark near the edge of the image in cases where the vehicle extends
                        past the edge.
                    `,
                    examples: [
                        {
                            description: 'This image has been marked up poorly. The bicycle should be boxed as well. The rest of the car boxes look good though.',
                            positive: false,
                            image: 'wellMarked.jpg'
                        },
                        {
                            description: 'This image has been marked up well.',
                            positive: true,
                            image: 'wellMarked2.jpg'
                        },
                        {
                            description: 'This image has been poorly marked up. The boxes are all too loose.',
                            positive: false,
                            image: 'poorlyMarked2.png'
                        },
                        {
                            description: 'This image has been marked up well.',
                            positive: true,
                            image: 'redoneMarking.png'
                        },
                        {
                            description: 'This image has been marked up well. Notice the car in the background that is covered by another car. Notice how only the visible portion of the car is annotated. Same with the white car on the left side, as it is covered by the person and goes off the side of the screen, it is still annotated, because you know it is a vehicle.',
                            positive: true,
                            image: 'wellMarked3.png'
                        },
                        {
                            description: 'This image has been marked up well. Notice how even the cars seen through the windshield are marked.',
                            positive: true,
                            image: 'wellMarked4.png'
                        },
                        {
                            description: 'This image has been marked up well. Notice how the bicycle is boxed.',
                            positive: true,
                            image: 'bike.jpg'
                        },
                        {
                            description: 'This image has been marked up well. Notice how the billboard in the background is marked up because it is a realistic image of a car, but the red billboard is artwork, so it is not marked.',
                            positive: true,
                            image: 'carBillboard.png'
                        },
                        {
                            description: 'This image has been marked up well. Escooters are considered land vehicles.',
                            positive: true,
                            image: 'escooter.png'
                        },
                        {
                            description: 'This image has been marked up well. Mopeds are considered land vehicles',
                            positive: true,
                            image: 'moped.jpg'
                        },
                        {
                            description: 'This image has been marked up well. Wheelchairs are considered land vehicles',
                            positive: true,
                            image: 'wheelchair.png'
                        },
                        {
                            description: 'This image has been marked up well. This is a toy car, but it is operable and can be riden, so it should be annotated.',
                            positive: true,
                            image: 'operableCar.png'
                        },
                        {
                            description: 'This is art of a car, and should not be boxed.',
                            positive: true,
                            image: 'carArt.jpg'
                        },
                        {
                            description: 'Trailers should be boxed. Boats should not be boxed.',
                            positive: true,
                            image: 'boatOnTrailer.png'
                        },
                        {
                            description: 'Planes should not be boxed.',
                            positive: true,
                            image: 'plane.jpg'
                        },
                    ]
                }
            ]
        },
        knife: {
            id: 48,
            header: 'Knife localization',
            description: `
                In this task, you will be presented an image with pre-boxed knives. You are able to adjust existing boxes, delete incorrect boxes, and draw any missing boxes as needed.
            `,
            workflow: [`
                <strong> Localization: </strong>
                <ul>
                    <li> If an existing knife box is too loose, please adjust it by clicking and dragging its corner points. </li>
                    <li> If there is a box around a non-knife, please delete the box entirely by clicking on a corner of the box and then pressing <code>d</code> key. </li>
                    <li> If there is a knife with no box around it, please draw the missing box by clicking on the top left corner and dragging a box around the knife. </li>
                    <li> If there is an area of the image that could be confused for a knife or that you are unsure of, please draw a box around it and label it as an "ignore" region in the subsequent step. \
                        Ignore regions should be tightly bound around the area or object of interest, not mass regions. </li>
                </ul>
                `,
                `
                <strong> Labeling: </strong>
                For each of your finalized knife boxes, please click the corner and label using the <code>1</code> (green) or <code>i</code> (red) keys on your keyboard.
                <ol type="1">
                    <li> <b>Green:</b> This is the default label. You can reset it with the <code>1</code> key if you change to another label and later want to change back. </li>
                    <li> <b>Red:</b> an ignore region </li>
                </ol>
                If you are unsure about an image for any reason, please press <code>Skip</code>.
                When you are finished localizing and labeling the knives, please use the <code>Next</code> button, <code>spacebar</code> or <code>right-arrow</code> key to proceed to next image.
            `],
            rules: [
                {
                    header: 'Examples of Well Annotated Knives',
                    description: `
                        When redrawing the box, make sure all four corneres are as tightly around the knife as you can.
                        Mark near the edge of the image in cases where the knife extends
                        past the edge.
                    `,
                    examples: [
                        {
                            description: 'The four corners of this knife are tightly marked.',
                            positive: true,
                            image: 'obviousKnife.png'
                        },
                        {
                            description: 'A machete is considered a knife.',
                            positive: true,
                            image: 'machete.png'
                        },
                        {
                            description: 'This machete is well marked.',
                            positive: true,
                            image: 'machete2.jpg'
                        },
                        {
                            description: 'This knife is well boxed.',
                            positive: true,
                            image: 'knife2.jpg'
                        },
                        {
                            description: 'This knife is well boxed.',
                            positive: true,
                            image: 'knife3.png'
                        },
                        {
                            description: 'This knife is well boxed.',
                            positive: true,
                            image: 'knife4.png'
                        }
                    ]
                },
                {
                    header: 'Bounding Box Adjustments',
                    description: `
                        Be sure that the bounding boxes are tightly drawn around knives.
                    `,
                    examples: [
                        {
                            description: 'This box is too loose and should be adjusted to be tighter around the knife.',
                            positive: false,
                            image: 'looseKnife.png'
                        },
                        {
                            description: 'This knife has been well boxed.',
                            positive: true,
                            image: 'looseKnifeFixed.png'
                        }
                    ]
                },
                {
                    header: 'Missing boxes',
                    description: `
                        Ensure all knives in the image are boxed.
                    `,
                    examples: [
                        {
                            description: 'This image is missing a knife box.',
                            positive: false,
                            image: 'missingKnife.png'
                        },
                        {
                            description: 'This image has been fixed with the knife boxed.',
                            positive: true,
                            image: 'missingKnifeFixed.png'
                        }
                    ]
                },
                {
                    header: 'Truncation and Occlusion',
                    description: `
                        Be sure to box all knives even if they extend off the side of the image or are occluded in the image.
                        For knives that extend off the edge of the image, mark to the edge of the image, but do not try to extend off the side of the image.
                    `,
                    examples: [
                        {
                            description: 'This machete extends off the side of the image, be sure to mark to the side of the image.',
                            positive: true,
                            image: 'truncatedKnife.png'
                        },
                        {
                            description: 'This machete is occluded behind a persons head. Make sure to only mark the visual portion of the knife. If you could see the handle in his hand, you would draw the box around the whole machete, including the occluded portion. Because you can only see the top portion of this knife, you just draw the box around the visual portion.',
                            positive: true,
                            image: 'occludedKnives.png'
                        }
                    ]
                },
                {
                    header: 'Use ignore regions for areas that could be a knife, but you are unsure',
                    description: `
                        When in doubt about a knife, you can choose to ignore a region of the image instead of skip the whole image
                    `,
                    examples: [
                        {
                            description: 'This seems it could be a knife, but I am not positive if it is. This could be marked either a knife or an ignore region if you were not sure.',
                            positive: true,
                            image: 'ignore.png'
                        },
                        {
                            description: 'This seems it could be a knife, but I am not positive if it is. I would mark this as an ignore box.',
                            positive: true,
                            image: 'ignore2.png'
                        }
                    ]
                },
                {
                    header: 'Billboard, posters, and artwork',
                    description: `
                        Box images of knives shown on billboards and posters if they are true to life images. For artwork of knives, these should not be boxed.
                    `,
                    examples: [
                        {
                            description: 'True to life images of knives should be marked as knives.',
                            positive: true,
                            image: 'knifeBillboard.png'
                        },
                        {
                            description: 'Artwork of knives should not be boxed.',
                            positive: true,
                            image: 'knifeArt.png'
                        }
                    ]
                },
                {
                    header: 'Toy Knives',
                    description:
                    `
                        Do not box toy knives.
                    `,
                    examples: [
                        {
                            description: 'This is a toy knife and should not be boxed.',
                            positive: true,
                            image: 'toyKnife.png'
                        }
                    ]
                }
            ]
        },
        vehicle_eval: {
            id: 40,
            header: 'Land vehicle localization',
            description: `
                In this task, you will be presented an image with pre-boxed land-vehicle(s), and are asked to adjust boxes that are not tightly marked
                around a vehicle, delete boxes that are not around a vehicle and draw new boxes around unboxed vehicles.
            `,
            workflow: [`
                <strong> Localization: </strong>
                <ul>
                    <li> If an existing vehicle box is too loose, please adjust it by clicking and dragging its corner points. </li>
                    <li> A vehicle is a car, truck, motorcycle, segway, electric scooter, bicycle and/or wheelchair </li>
                    <li> A vehicle includes things like shopping carts, strollers, wagons, etc. When in doubt of if something with wheels counts, consider it a vehicle </li>
                    <li> Do NOT box planes and boats (ie non-land vehicles). Trailers carrying boats/planes should be annotated </li>
                    <li> If there is a box around a non-vehicle, please delete the box entirely by clicking on a corner of the box and then pressing <code>d</code> key. </li>
                    <li> If there is a vehicle with no box around it, please draw the missing box by clicking and dragging a box around the vehicle. </li>
                    <li> If there is an area of the image that could be confused for a vehicle or that you are unsure of, please draw a box around it and label it as an "ignore" region in the subsequent step. \
                        Ignore regions should be tightly bound around the area or object of interest, not mass regions. </li>
                </ul>
                `,
                `
                <strong> Labeling: </strong>
                For each of your finalized vehicle boxes, please click the corner and label using the <code>1</code> (green) or <code>i</code> (red) keys on your keyboard.
                <ol type="1">
                    <li> <b>Green:</b> This is the default label. You can reset it with the <code>1</code> key if you change to another label and later want to change back. </li>
                    <li> <b>Red:</b> an ignore region </li>
                </ol>
                If you are unsure about an image for any reason, please press <code>Skip</code>.
                When you are finished localizing and labeling the vehicles, please use the <code>Next</code> button, <code>spacebar</code> or <code>right-arrow</code> key to proceed to next image.
            `],
            rules: [
                {
                    header: 'Be sure to closely box each vehicle in the image.',
                    description: `
                        Adjust the boxes around the vehicles as necessary, and as tightly as you can.
                        Mark near the edge of the image in cases where the vehicle extends
                        past the edge.
                    `,
                    examples: [
                        {
                            description: 'This image has been marked up poorly. The bicycle should be boxed as well. The rest of the car boxes look good though.',
                            positive: false,
                            image: 'wellMarked.jpg'
                        },
                        {
                            description: 'This image has been marked up well. Be sure to mark up partial vehicles.',
                            positive: false,
                            image: 'harderGTEx.jpg'
                        },
                        {
                            description: 'This image has been well marked. Please do not miss any small background wheels.',
                            positive: true,
                            image: 'smallWheels.png'
                        },
                        {
                            description: 'This image has been marked up well.',
                            positive: true,
                            image: 'wellMarked2.jpg'
                        },
                        {
                            description: 'This image has been poorly marked up. The boxes are all too loose.',
                            positive: false,
                            image: 'poorlyMarked2.png'
                        },
                        {
                            description: 'This image has been marked up well.',
                            positive: true,
                            image: 'redoneMarking.png'
                        },
                        {
                            description: 'This image has been marked up well. Notice the car in the background that is covered by another car. Notice how only the visible portion of the car is annotated. Same with the white car on the left side, as it is covered by the person and goes off the side of the screen, it is still annotated, because you know it is a vehicle.',
                            positive: true,
                            image: 'wellMarked3.png'
                        },
                        {
                            description: 'This image has been marked up well. Notice how even the cars seen through the windshield are marked.',
                            positive: true,
                            image: 'wellMarked4.png'
                        },
                        {
                            description: 'This image has been marked up well. Notice how the bicycle is boxed.',
                            positive: true,
                            image: 'bike.jpg'
                        },
                        {
                            description: 'This image has been marked up well. Notice how the billboard in the background is marked up because it is a realistic image of a car, but the red billboard is artwork, so it is not marked.',
                            positive: true,
                            image: 'carBillboard.png'
                        },
                        {
                            description: 'This image has been marked up well. Escooters are considered land vehicles.',
                            positive: true,
                            image: 'escooter.png'
                        },
                        {
                            description: 'This image has been marked up well. Mopeds are considered land vehicles',
                            positive: true,
                            image: 'moped.jpg'
                        },
                        {
                            description: 'This image has been marked up well. A toy car is not considered a car because it cannot be riden.',
                            positive: true,
                            image: 'toyCar.png'
                        },
                        {
                            description: 'This image has been marked up well. This is a toy car, but it is operable and can be riden, so it should be annotated.',
                            positive: true,
                            image: 'operableCar.png'
                        },
                        {
                            description: 'This image has been marked up well. Wheelchairs are considered land vehicles',
                            positive: true,
                            image: 'wheelchair.png'
                        },
                        {
                            description: 'This is art of a car, and should not be boxed.',
                            positive: true,
                            image: 'carArt.jpg'
                        },
                        {
                            description: 'Trailers should be boxed. Boats should not be boxed.',
                            positive: true,
                            image: 'boatOnTrailer.png'
                        },
                        {
                            description: 'Planes should not be boxed.',
                            positive: true,
                            image: 'plane.jpg'
                        },
                    ]
                }
            ]
        },
        multiclass: {
            id: 45,
            header: 'Multi-class localization',
            description: `
                In this task, you will be presented with a prompt of what object to annotate and an image with pre-boxed objects(s), and are asked to adjust boxes that are not tightly marked
                around an object, delete boxes that are not around the object of interest and draw new boxes around unboxed objects of interest.
            `,
            workflow: [`
                <strong> Localization: </strong>
                <ul>
                    <li> If an existing object box is too loose, please adjust it by clicking and dragging its corner points. </li>
                    <li> If there is a box around a non-object, please delete the box entirely by clicking on a corner of the box and then pressing <code>d</code> key. </li>
                    <li> If there is an object of interest with no box around it, please draw the missing box by clicking and dragging a box around the object. </li>
                    <li> If there is an area of the image that could be confused for the object of interest or that you are unsure of, please draw a box around it and label it as an "ignore" region in the subsequent step. \
                        Ignore regions should be tightly bound around the area or object of interest, not mass regions. </li>
                </ul>
                `,
                `
                <strong> Labeling: </strong>
                For each of your finalized object boxes, please click the corner and label using the <code>1</code> (green) or <code>i</code> (red) keys on your keyboard.
                <ol type="1">
                    <li> <b>Green:</b> This is the default label. You can reset it with the <code>1</code> key if you change to another label and later want to change back. </li>
                    <li> <b>Red:</b> an ignore region </li>
                </ol>
                If you are unsure about an image for any reason, please press <code>Skip</code> and send an email with what you were unsure about to data@roc.ai so we can clarify the instructions.
                When you are finished localizing and labeling the objects, please use the <code>Next</code> button, <code>spacebar</code> or <code>right-arrow</code> key to proceed to next image.
            `],
            rules: [
                {
                    header: 'Well Marked Objects',
                    description: 
                    `
                        Be sure to have tight boxes around the objects that are supposed to be annotated.
                    `,
                    examples: [
                        {
                            description: 'This image was asked to mark all cups. This is well marked for all cups. As a note, cups include coffee cups, dixie cups, glass cups, mugs, and tea cups but does NOT include wine glasses or water bottles.',
                            positive: true,
                            image: 'cup.png'
                        },
                        {
                            description: 'This image was asked to mark all cellphones. This image is well marked for all cellphones.',
                            positive: true,
                            image: 'cellphoneWellMarked.png'
                        },
                        {
                            description: 'Be sure to scan the whole image and tightly mark the object. This image was marked for cellphones.',
                            positive: true,
                            image: 'cellOnTable.png'
                        }
                    ]
                },
                {
                    header: 'Bounding Box Adjustments',
                    description: `
                        When redrawing the box, make sure all four corneres are as tightly around the object as you can.
                        Mark near the edge of the image in cases where the object extends
                        past the edge.
                    `,
                    examples: [
                        {
                            description: 'The object of interest is hats. The four corners of this hat are too loosely marked. As a note, hats include bandannas, baseball caps, beanies, bowler hats, cowboy hats, fedoras, headbands, headscarves, sombreros, sun hats, top hats, turbans, and visors.',
                            positive: false,
                            image: 'hatsLoose.png'
                        },
                        {
                            description: "This has fixed the image to be a tightly marked box.",
                            positive: true,
                            image: 'hatsTight.png'
                        }
                    ]
                },
                {
                    header: 'Delete False Boxes',
                    description: `
                        Any box that is around not the object of interest (i.e. a false positive), please delete using the "d" key.
                    `,
                    examples: [
                        {
                            description: "The category is balls (sports). This box is around a piece of the background and not around a ball. Please delete the box. As a note, a sports ball includes baseballs, basketballs, soccer balls, footballs, golf balls, ping pong balls, rugby balls, tennis balls, and volleyballs",
                            positive: false,
                            image: 'sportsBalls.png'
                        },
                        {
                            description: "This image has been fixed.",
                            positive: true,
                            image: 'sportsBallsFixed.png'
                        }
                    ]
                },
                {
                    header: 'Be sure to box any missed objects',
                    descriptions:  `
                        Any image that has an object that has been missed should be thouroughly marked up.
                    `,
                    examples: [
                        {
                            description: "This category is boats. Notice the background boat that was missed? Please be sure to individually box all objects. As a note, boats includes barges, canoes, gondolas, kayaks, rafts, sailboats, ships, jet skis, and submarines",
                            positive: false,
                            image: 'boats.png'
                        },
                        {
                            description: "This image has been marked up to box the missed boat.",
                            positive: true,
                            image: 'boatsFixed.png'
                        }
                    ]
                },
                {
                    header: 'Truncation and Occlusion',
                    description: `
                        If an object goes off the edge of the image, mark the object to the edge of the image, but do not try to extend the box off the side.
                        If an object is partially occluded, mark the visual portion.
                        If something is partially occluding the object, mark all visual portions of the object i.e. if someones hand is covering part of the object, but you can see the top of the object and bottom of the object, mark the whole object.
                    `,
                    examples: [
                        {
                            description: 'This category is books. This book is cutoff at the edge of the image. Be sure to annotate to just the edge of the image',
                            positive: true,
                            image: 'truncatedBook.png'
                        },
                        {
                            description: 'This category is bottles. This waterbottle is partially occluded, be sure to mark all visable portions',
                            positive: true,
                            image: 'waterBottleOccluded.png'
                        },
                        {
                            description: 'This plane is occluded in the front, be sure to only mark the visable portion',
                            positive: true,
                            image: 'occludedAirplane.png'
                        },
                        {
                            description: 'This cellphone is occluded, please be sure to mark all visible portions of an occluded object.',
                            positive: true,
                            image: 'partiallyOccludedCell.png'
                        },
                        {
                            description: 'This cellphone is occluded, please be sure to mark only the visible portion.',
                            positive: true,
                            image: 'occludedCell.png'
                        }
                    ]
                },
                {
                    header: 'Billboards, posters, and artwork',
                    description: `
                        Be sure to mark photo realistic objects, but not artwork of the object.
                    `,
                    examples: [
                        {
                            description: 'The category is teddy bears. This billboard has a photo realistic image of a teddy bear, so the bear should be boxed.',
                            positive: true,
                            image: 'teddyBearBillboard.png'
                        },
                        {
                            description: 'This category is megaphone. Artwork should NOT be boxed.',
                            positive: true,
                            image: 'megaphoneArt.png'
                        }
                    ]
                },
                {
                    header: 'Ignore region',
                    description: `If there is an area of the image that could be confused for the object of interest or that you are unsure of, please draw a box around it and label it as an "ignore" region.
                    `,
                    examples: [
                        {
                            description: 'If the category is dog, this is a correct use of an "ignore" region box. It contains a part that looks like it might be a dog, but I am not positive when I zoom in and look. No changes are needed.',
                            positive: true,
                            image: 'dogIgnore.png'
                        },
                        {
                            description: 'This is an incorrect use of an "ignore" region. Ignore regions should be tightly boxed around the area of interest. You should not box large background areas as "ignore."' ,
                            positive: false,
                            image: 'dogIgnoreWrong.png'
                        },
                        {
                            description: 'If the category is cell phone, this is the correct use of an ignore region where I can see what may be a cellphone in a hand, but it could also be a cigarette box, so I will choose to ignore it.',
                            positive: true,
                            image: 'ignoreCell.png'
                        }
                    ]

                }
            ]
        },
        face: {
            id: 49,
            header: 'Face localization',
            description: `
                In this task, you will be presented an image with pre-boxed face(s), and are asked to adjust boxes that are not tightly marked
                around a face, delete boxes that are not around a face and draw new boxes around unboxed faces. If all faces are already well boxed, then please press
                <code>Next</code>. If there is a box around a non-face, then please delete the box entirely
                by clicking a corner of the box and then pressing "d". If there is an image where there is not a box around a face,
                then please draw in the box by clicking and dragging your mouse. If there are no suitable faces in the image
                (i.e. there are boxes around non-faces), please delete all boxes and then press <code>Next</code>.
                If you are unsure about an image for any reason, please press <code>Skip</code>.
            `,
            workflow: [`
                <strong> Localization: </strong>
                <ul>
                    <li> If an existing face box is too loose, please adjust it by clicking and dragging its corner points. </li>
                    <li> If there is a box around a non-face, please delete the box entirely by clicking on a corner of the box and then pressing <code>d</code> key. </li>
                    <li> If there is a face with no box around it, please draw the missing box by clicking and dragging a box around it. </li>
                    <li> If there is an area of the image that could be confused for a face or that you are unsure of, please draw a box around it and label it as an "ignore" region in the subsequent step. \
                         Ignore regions should be tightly bound around the area or object of interest, not mass regions. </li>
                    <li> Do not box faces that have neither eyes visible (i.e. the back of the head). If one eye is visible, please box the face.</li>
                    <li> Try to tightly box from ear-to-ear width wise, and forehead-to-chin height wise. For profile/side-view faces, please make sure to include the nose tip in the box. </li>
                    <li> Zoom in as necessary to accurately box small faces. </li>
                    <li> Clear erroneous boxes with the <code>Undo</code> button or <code>u</code> key. </li>\
                    <li> When redrawing the box, make sure all four corners are as tightly around the face as you can. </li>
                    <li> Mark the edge of the image in cases where the face extends past the edge. </li>
                    <li> If there are no faces in an image, please be sure to delete all boxes and click <code>Next</code>. </li>
                    <li> Proceed to the next image once all of the faces are properly marked using the <code>Next</code> button, <code>Spacebar</code> or <code>right-arrow</code> keys </li>
                     
                </ul>
                `,
                `
                <strong> Labeling: </strong>
                For each of your finalized face boxes, please click the corner and label using the <code>1</code> (green) or <code>i</code> (red) keys on your keyboard.
                <ol type="1">
                    <li> <b>Green:</b> a fully visible face with no occlusion or truncation. This is the default label. You can reset it with the <code>1</code> key if you change to another label and later want to change back. </li>
                    <li> <b>Red:</b> an ignore region </li>
                </ol>
                When you are finished localizing and labeling the faces, please use the <code>Next</code> button, <code>spacebar</code> or <code>right-arrow</code> key to proceed to next image.
            `],
            rules: [
                {
                    header: 'Be sure to closely box each face in the image.',
                    description: `
                        Adjust the boxes around the faces as necessary, and as tightly as you can.
                        Mark near the edge of the image in cases where the face extends
                        past the edge.
                    `,
                    examples: [
                        {
                            description: 'This gif shows how to properly box faces.',
                            positive: true,
                            image: 'face_localization.gif'
                        },
                        {
                            description: 'This face is poorly boxed because the box is too loose. You do not need to include the hair or the ears in this.',
                            positive: false,
                            image: 'looseFace.jpg'
                        },
                        {
                            description: 'This image is well marked up.',
                            positive: true,
                            image: 'wellMarked.jpg'
                        },
                        {
                            description: 'This face is poorly boxed because the box is too loose. This box should stop around the chin and not cover the ear like this.',
                            positive: false,
                            image: 'looseFace2.jpg'
                        },
                        {
                            description: 'This is poorly boxed because the background face is not boxed AND the front face is poorly boxed. (the front box should end at the chin and should not include the ear).',
                            positive: false,
                            image: 'missingFace.png'
                        },
                        {
                            description: 'This image is well marked up.',
                            positive: true,
                            image: 'wellMarkedFace.png'
                        },
                        {
                            description: 'This is poorly boxed because the boxes are too loose and should not include so much of the top of the head or the ears.',
                            positive: false,
                            image: 'looseFace.png'
                        },
                        {
                            description: 'This image is well marked up.',
                            positive: true,
                            image: 'wellMarked2.png'
                        },
                        {
                            description: 'This image is well marked up.',
                            positive: true,
                            image: 'wellMarked3.png'
                        },
                        {
                            description: 'These faces are rotated, but well marked up. Notice that the child who you can only see the back of the head is not annotated.',
                            positive: true,
                            image: 'rotatedFaces.png'
                        }
                    ]
                },
                {
                    header: 'Occlusion/Truncation.',
                    description: `
                        Be sure to box all faces even if they extend off the side of the image or are occluded in the image. 
                        For faces that extend off the edge of the image, mark to the edge of the image, but do not try to extend off the side of the image.
                    `,
                    examples: [
                        {
                            description: 'These girls are wearing facemasks. Notice how they are fully annotated regardless of the occlusion',
                            positive: true,
                            image: 'faceMasks.png'
                        },
                        {
                            description: 'This girls face is truncated off the side of the page, please be sure to fully annotate images, even if the face goes off the side of the screen.',
                            positive: true,
                            image: 'truncated.jpg'
                        }
                    ]
                },
                {
                    header: 'Billboards, Posters, and Artwork',
                    description: `
                        Box images of faces shown on billboards and posters if they are true to life images. For artwork of faces, these should not be boxed.
                    `,
                    examples: [
                        {
                            description: 'Faces on billboards should be annotated if it is a photo real person and not artwork.',
                            positive: true,
                            image: 'billboardFace.png'
                        },
                        {
                            description: 'These faces are artwork, so they should not be annotated.',
                            positive: true,
                            image: 'art.png'
                        },
                        {
                            description: 'Faces on mannequins should NOT be annotated.',
                            positive: true,
                            image: 'manequins.png'
                        },

                    ]
                }
            ]
        },
    },
    logo_qc: {
        logo_qc: {
            id: 13,
            header: 'Logo image confirmation'
        }
    },
    tracking: {
        face: {
            id: 43,
            header: 'Face tracking',
            image: 'face_track.gif',
            description: `
                In this task, you will be presented with a series of video frames (images) for which you will be expected to annotate a "track" for each
                face in the series of images. You will choose a face in the first frame and box them, then proceed through the set of frames boxing
                the same face in each frame, "tracking" them as they move through the scene. Then you should check your work, finalize the track, and
                start the next track (with a different face.)
            `,
            workflow: [
                'Look at first image and identify a face to track.',
                'Box that face and ONLY that face, then proceed to the next frame by clicking <code>Next frame</code> or pressing the right arrow key or spacebar.',
                'Check the next frame for the same face you are tracking and box them if they appear. Continue doing so until you have reached the end of the set of images. If the face leaves the scene you should still continue through the rest of the images in case they reenter the scene.',
                'Once you reach the last frame, please go all the way back to the beginning of the track you have completed and check to make sure all the boxes for that track were saved properly and are well fitted to the face, adding or adjusting boxes as needed.',
                'Once you have checked your work, hit the <code>Finalize track</code> button. This will rewind to the frame at which you started the previous track.',
                'If there are more faces to track pick a new face and start a new track. Othwerwise, please press the <code>Next task</code> button to proceed to a new set of frames.',
                'When you think you have annotated all the tracks, go back to the first frame and look carefully for missed faces. This is especially important in complex scenes which contain faces which are very small in the background',
                'If you start a long task but need stop annotating, it\'s OK to quit in the middle. To do so, please finish annotating the track you are on and click "finalize track", then, log out or close the browser tab. Do NOT click "next task" unless all tracks have been completed!'
            ],
            rules: [
                {
                    header: 'Only mark one face for each run through the frames',
                    description: `
                        Make sure you choose one face to annotate through the set of frames. You can only draw one box per track for each frame, and each track should follow a single face (not multiple faces). Boxes for the current track are shown in green, while boxes for finalized tracks are shown in blue. Once you finish the first track, click
                        <code>Finalize track</code> before annotating other faces.
                    `,
                    examples: [
                        {
                            description: 'I have chosen to annotate this man standing in the foreground. I would box his face, then hit next frame (or use the right arrow key or spacebar).',
                            positive: true,
                            image: 'oneFace.jpg'
                        }
                    ]
                },
                {
                    header: 'Tightly box the visible part of the face.',
                    description: 'Be sure to tightly box the visible portion of the tracked face, as long as you know that this is the face being annotated. Please keep the boxes tight and do not extrapolate where the box should go.',
                    examples: [
                        {
                            description: 'Keep the annotations tight around the faces.',
                            positive: true,
                            image: 'wellMarked.jpg'
                        },
                        {
                            description: 'This face is too loose of a box.',
                            positive: false,
                            image: 'looseBox.jpg'
                        },
                    ]
                },
                {
                    header: 'You may receive a partially complete task',
                    description: `
                        When someone exits a task early, it will be readded to the queue after a couple days. As such, you may receive a partially completed task (even one that was started by someone else.) Sometimes you may receive a partially complete task that includes an incomplete TRACK (in other words, a track that was never finalized by clicking "finalize track").
                        Signs that you have resumed a task with an incomplete track include 1) you don't start on the first frame 2) you already have a "reference object" image and 3) when you go back a frame, there are green boxes displayed.
                        When you resume a task with an incomplete complete track, you must identify the face being tracked (using the reference object, and by looking back at previous frames) and complete that track, picking up from the frame where the other person left off.
                        If the incomplete track is actually complete (i.e, the face is boxed every time it appears), you can just click "finalize track" to mark it complete and continue normally.

                        In most cases, you will start on the first frame and there will only be blue boxes displayed. If that is the case, just pick a new face to track and continue normally.
                    `
                },
                {
                    header: 'Be mindful of your zoom level.',
                    description: `
                        It's a good idea to zoom in when necessary, however, you should also be careful to zoom out again if the object you're tracking starts to leave the frame. A common mistake is to forget you're zoomed in and to mistake the edge of your view for the edge of the image.
                        `,
                    examples: [
                        {
                            description: 'The annotator zoomed in to draw better boxes around the person in borwn but forgot to zoom out when the person started to leave the zoom area. The result is an invisible "wall" where the annotations stop, even though the person has not left the frame yet.',
                            positive: false,
                            image: 'zoom_error.gif'
                        }
                    ]
                },
                {
                    header: 'Fix errors in already-finalized tracks, but make sure they are added to their own unique tracks.',
                    description: `
                        If you notice that a FINALIZED track is missing a box, do NOT add the box if you are already annotating another track.
                        Instead you should note the frame number for the missing box, finish the current track you are annotating, navigate back to the frame number with the missing box, draw the box (creating a new “patch” track), and finalize that track.
                        As a reminder, each track should have exactly one face in it, so if multiple tracks have missing boxes, you may need multiple patch tracks.
                    `,
                },
            ]
        },
        vehicle: {
            id: 44,
            header: 'Vehicle tracking',
            image: 'vehicle_track.gif',
            description: `
                In this task, you will be presented with a series of video frames (images) for which you will be expected to annotate a "track" for each
                vehicle in the series of images. You will choose a vehicle in the first frame and box them, then proceed through the set of frames boxing
                the same vehicle in each frame, "tracking" them as they move through the scene. Then you should check your work, finalize the track, and
                start the next track (with a different vehicle.)
            `,
            workflow: [
                'Look at first image and identify a vehicle to track.',
                'Box that vehicle and ONLY that vehicle, then proceed to the next frame by clicking <code>Next frame</code> or pressing the right arrow key or spacebar.',
                'Check the next frame for the same vehicle you are tracking and box them if they appear. Continue doing so until you have reached the end of the set of images. If the vehicle leaves the scene you should still continue through the rest of the images in case they reenter the scene.',
                'Once you reach the last frame, please go all the way back to the beginning of the track you have completed and check to make sure all the boxes for that track were saved properly and are well fitted to the vehicle, adding or adjusting boxes as needed.',
                'Once you have checked your work, hit the <code>Finalize track</code> button. This will rewind to the frame at which you started the previous track.',
                'If there are more vehicles to track pick a new vehicle and start a new track. Othwerwise, please press the <code>Next task</code> button to proceed to a new set of frames.',
                'When you think you have annotated all the tracks, go back to the first frame and look carefully for missed vehicles. This is especially important in complex scenes which contain vehicles which are very small in the background',
                'If you start a long task but need stop annotating, it\'s OK to quit in the middle. To do so, please finish annotating the track you are on and click "finalize track", then, log out or close the browser tab. Do NOT click "next task" unless all tracks have been completed!'
            ],
            rules: [
                {
                    header: 'Only mark one vehicle for each run through the frames',
                    description: `
                        Make sure you choose one vehicle to annotate through the set of frames. You can only draw one box per track for each frame, and each track should follow a single vehicle (not multiple vehicles). Boxes for the current track are shown in green, while boxes for finalized tracks are shown in blue. Once you finish the first track, click
                        <code>Finalize track</code> before annotating other vehicles.
                    `,
                    examples: [
                        {
                            description: 'I have chosen to annotate this man standing in the foreground. I would box his face, then hit next frame (or use the right arrow key or spacebar).',
                            positive: true,
                            image: 'oneFace.jpg'
                        }
                    ]
                },
                {
                    header: 'Tightly box the visible part of the vehicle.',
                    description: 'Be sure to tightly box the visible portion of the tracked vehicle, as long as you know that this is the vehicle being annotated. Please keep the boxes tight and do not extrapolate where the box should go.'
                },
                {
                    header: 'You may receive a partially complete task',
                    description: `
                        When someone exits a task early, it will be readded to the queue after a couple days. As such, you may receive a partially completed task (even one that was started by someone else.) Sometimes you may receive a partially complete task that includes an incomplete TRACK (in other words, a track that was never finalized by clicking "finalize track").
                        Signs that you have resumed a task with an incomplete track include 1) you don't start on the first frame 2) you already have a "reference object" image and 3) when you go back a frame, there are green boxes displayed.
                        When you resume a task with an incomplete complete track, you must identify the vehicle being tracked (using the reference object, and by looking back at previous frames) and complete that track, picking up from the frame where the other person left off.
                        If the incomplete track is actually complete (i.e, the vehicle is boxed every time it appears), you can just click "finalize track" to mark it complete and continue normally.

                        In most cases, you will start on the first frame and there will only be blue boxes displayed. If that is the case, just pick a new vehicle to track and continue normally.
                    `
                },
                {
                    header: 'Be mindful of your zoom level.',
                    description: `
                        It's a good idea to zoom in when necessary, however, you should also be careful to zoom out again if the object you're tracking starts to leave the frame. A common mistake is to forget you're zoomed in and to mistake the edge of your view for the edge of the image.
                        `,
                    examples: [
                        {
                            description: 'The annotator zoomed in to draw better boxes around the person in borwn but forgot to zoom out when the person started to leave the zoom area. The result is an invisible "wall" where the annotations stop, even though the person has not left the frame yet.',
                            positive: false,
                            image: 'zoom_error.gif'
                        }
                    ]
                },
                {
                    header: 'Fix errors in already-finalized tracks, but make sure they are added to their own unique tracks.',
                    description: `
                        If you notice that a FINALIZED track is missing a box, do NOT add the box if you are already annotating another track.
                        Instead you should note the frame number for the missing box, finish the current track you are annotating, navigate back to the frame number with the missing box, draw the box (creating a new “patch” track), and finalize that track.
                        As a reminder, each track should have exactly one vehicle in it, so if multiple tracks have missing boxes, you may need multiple patch tracks.
                    `,
                }
            ]
        },
        person: {
            id: 36,
            header: 'Person tracking',
            image: 'person-tracking.gif',
            description: `
                In this task, you will be presented with a series of video frames (images) for which you will be expected to annotate a "track" for each
                person in the series of images. You will choose a person in the first frame and box them, then proceed through the set of frames boxing
                the same person in each frame, "tracking" them as they move through the scene. Then you should check your work, finalize the track, and
                start the next track (with a different person.)
            `,
            workflow: [
                'Look at first image and identify a person to track.',
                'Box that person, then proceed to the next frame by clicking <code>Next frame</code> or pressing the right arrow key or spacebar.',
                'Check the next frame for the same person you are tracking and box them if they appear. Continue doing so until you have reached the end of the set of images. If the person leaves the scene you should still continue through the rest of the images in case they reenter the scene.',
                'Once you reach the last frame, please go all the way back to the beginning of the track you have completed and check to make sure all the boxes for that track were saved properly and are well fitted to the person, adding or adjusting boxes as needed.',
                'Once you have checked your work, hit the <code>Finalize track</code> button. This will rewind to the frame at which you started the previous track.',
                'If there are more persons to track pick a new person and start a new track. Othwerwise, please press the <code>Next task</code> button to proceed to a new set of frames.',
                'When you think you have annotated all the tracks, go back to the first frame and look carefully for missed persons. This is especially important in complex scenes which contain persons which are very small in the background',
                'If you start a long task but need stop annotating, it\'s OK to quit in the middle. To do so, please finish annotating the track you are on and click "finalize track", then, log out or close the browser tab. Do NOT click "next task" unless all tracks have been completed!'
            ],
            rules: [
                {
                    header: 'Only mark one person for each run through the frames',
                    description: `
                        Make sure you choose one person to annotate through the set of frames. You can only draw one box per track for each frame, and each track should follow a single person (not multiple people). Boxes for the current track are shown in green, while boxes for finalized tracks are shown in blue. Once you finish the first track, click
                        <code>Finalize track</code> before annotating other people.
                    `,
                    examples: [
                        {
                            description: 'I have chosen to annotate this woman standing behind the red car. I would box her, then hit next frame (or use the right arrow key or spacebar).',
                            positive: true,
                            image: 'chosenPerson.jpg'
                        },
                        {
                            description: 'In this next frame shown, I would annotated the SAME woman by boxing her and selecting next frame.',
                            positive: true,
                            image: 'chosenPerson1.jpg'
                        },
                        {
                            description: 'After completing the first track, you should double check your work, then select <code>Finalize track</code>. Then you can begin tracking another person. This image shows the first person tracked in blue and the new track in green.',
                            positive: true,
                            image: 'secondTrack.jpg'
                        },
                        {
                            description: 'Do NOT annotate more than one person in the same box.',
                            positive: false,
                            image: 'twoPeopleSameBox.png'
                        },
                    ]
                },
                {
                    header: 'Mark people seen through windows or cars.',
                    description: `
                        Be sure to mark people seen through windows or who can be seen driving cars.
                    `,
                    examples:[
                        {
                            description: 'In this image, a woman can be seen through the glass, please be sure to mark her (and track her through all frames).',
                            positive: true,
                            image: 'womanInside.jpg'
                        }
                    ]
                },
                {
                    header: 'Tightly box the visible part of the person.',
                    description: 'Be sure to tightly box the visible portion of the tracked person, as long as you know that this is the person being annotated. Please keep the boxes tight and do not extrapolate where the box should go.',
                    examples:[
                        {
                            description: 'This woman is tightly boxed.',
                            positive: true,
                            image: 'tightlyBoxed.jpg'
                        },
                        {
                            description: 'This woman is too loosely boxed',
                            positive: false,
                            image: 'notTightlyBoxed.jpg'
                        },
                        {
                            description: 'This woman has been extrapolated and should not be.',
                            positive: false,
                            image: 'extrapolated.jpg'
                        },
                        {
                            description: 'This woman is occluded by the log, but she is visible both above and below it. Please include all of the visible person in one box.',
                            positive: true,
                            image: 'occludedPortion.jpg'
                        },
                        {
                            description: 'The bottom portion of this woman is occluded by the pile of wood. She should only be boxed for the visible portion.',
                            positive: true,
                            image: 'occludedBottom.jpg'
                        }
                    ]
                },
                {
                    header: 'You may receive a partially complete task',
                    description: `
                        When someone exits a task early, it will be readded to the queue after a couple days. As such, you may receive a partially completed task (even one that was started by someone else.) Sometimes you may receive a partially complete task that includes an incomplete TRACK (in other words, a track that was never finalized by clicking "finalize track").
                        Signs that you have resumed a task with an incomplete track include 1) you don't start on the first frame 2) you already have a "reference object" image and 3) when you go back a frame, there are green boxes displayed.
                        When you resume a task with an incomplete complete track, you must identify the person being tracked (using the reference object, and by looking back at previous frames) and complete that track, picking up from the frame where the other person left off.
                        If the incomplete track is actually complete (i.e, the person is boxed every time they appear), you can just click "finalize track" to mark it complete and continue normally.

                        In most cases, you will start on the first frame and there will only be blue boxes displayed. If that is the case, just pick a new person to track and continue normally.
                    `
                },
                {
                    header: 'Be mindful of your zoom level.',
                    description: `
                        It's a good idea to zoom in when necessary, however, you should also be careful to zoom out again if the object you're tracking starts to leave the frame. A common mistake is to forget you're zoomed in and to mistake the edge of your view for the edge of the image.
                        `,
                    examples: [
                        {
                            description: 'The annotator zoomed in to draw better boxes around the person in borwn but forgot to zoom out when the person started to leave the zoom area. The result is an invisible "wall" where the annotations stop, even though the person has not left the frame yet.',
                            positive: false,
                            image: 'zoom_error.gif'
                        }
                    ]
                },
                {
                    header: 'Fix errors in already-finalized tracks, but make sure they are added to their own unique tracks.',
                    description: `
                        If you notice that a FINALIZED track is missing a box, do NOT add the box if you are already annotating another track.
                        Instead you should note the frame number for the missing box, finish the current track you are annotating, navigate back to the frame number with the missing box, draw the box (creating a new “patch” track), and finalize that track.
                        As a reminder, each track should have exactly one person in it, so if multiple tracks have missing boxes, you may need multiple patch tracks.'
                    `,
                },
            ]
        },
        person_test: {
            id: 30,
            header: 'Person tracking',
            image: 'person-tracking.gif',
            description: `
                In this task, you will be presented with a series of images where you will be expected to annotate the "track" of each
                person in the series of images. This will involve sets of no more than 100 images where you will choose a person, and box them through the
                set of images, "tracking" them as they move through. These images are all still shots from a video, so you will be marking each
                frame of the video. When in doubt, if you know this is the same person you have been tracking from previous frames, they should
                be marked in the current frame too.
            `,
            workflow: [
                'Look at first image and identify a person to track.',
                'Box that person and ONLY that person, then proceed to the next frame by clicking <code>Next frame</code> or pressing the right arrow key or spacebar.',
                'Check the next frame for the same person you are tracking and box them if they appear. Continue doing so until they have left the scene or you have reached the end of the set of images.',
                'Once you have determined that the person has either exited the scene or you have run out of frames, hit the <code>Finalize track</code> button. This will rewind to the frame at which you started the previous track.',
                'If there are more people to track, then pick a new person and start a new track! Othwerwise, please press the <code>Next task</code> button to proceed to a new set of frames.'
            ]
        }
    },
    tracking_merge: {
        tracking_merge: {
            id: 33,
            header: 'Merge tracks',
            description: `
                In this task, you will be determining if two annotations are tracking the same person. You will be presented  with two tracks, and you will select <code>Yes</code> if you are confident
                the track is the same person, <code>Unsure</code> if you are unsure about the two tracks, or <code>No</code> if you know this is not the same person.
            `,
            workflow: [

            ]
        }
    },
    // annotator_eval: {
    //     license_plate_labeling: {
    //         id: 40,
    //         header: 'License plates',
    //         description: `
    //             In this task, you will be presented an image of a cropped license plate, and you will be asked to determine
    //             the country and subregion/state of origin of the plate, as well as whether the plate number is legible and
    //             whether there are <a href="https://tagmasterna.com/tmna-ct-45-lpr-n-stacked-plates"/>stacked characters
    //             (one character on top of the other)</a> present.
    //         `,
    //         plates: LICENSE_PLATES,
    //         workflow: [
    //             'Select the appropriate license plate country from the drop down menu. If it is a legitimate license plate and you are unable to determine the country then please select <code>Unknown</code>. If it is a dealer or decorative plate (sports team, etc.), then please select <code>Dealer/Decorative</code>. If it is not a license plate then please select <code>Not a plate</code>. If you recognize the country but it is not in the list, then please select <code>Other</code>.',
    //             'If you selected a country above, please proceed to select the appropriate <code>Subregion</code>, if applicable.',
    //             'Check the <code>Unclear?</code> checkbox if the alphanumeric registration ID (the letters and numbers central to the plate) are not legible for any reason.',
    //             'Check the <code>Partial?</code> checkbox if any of the plate characters cannot be read or seen, it should be marked as partial with the characters that can be read typed out in the <code>Plate Number</code> section. (It could be occluded on the left, right, or middle)',
    //             'If the plate number has <a href="https://tagmasterna.com/tmna-ct-45-lpr-n-stacked-plates"/>stacked characters (one character on top of the other)</a>, then please check the <code>Stacked?</code> checkbox.',
    //             'Proceed to the next plate when you are finished labeling the current license plate.'
    //         ],
    //         rules: [
    //             {
    //                 header: 'Select <code>Dealer/Decorative</code> for unofficial plates from car dealerships or things such as sports teams.',
    //                 examples: [
    //                     {
    //                         description: 'This is a license plate from a car dealership, please mark <code>Dealer/Decorative</code> from the <code>Country</code> dropdown and move on to the next plate.',
    //                         positive: true,
    //                         image: 'dealer.jpg'
    //                     }
    //                 ]
    //             },
    //             {
    //                 header: 'Check <code>Unclear?</code> for plates that have their number covered',
    //                 description: `
    //                     If it is a license plate but the central plate number is covered, then please check
    //                     the <code>Unclear?</code> checkbox. If you can still make out the country and region,
    //                     then please mark the country and state accordingly.
    //                 `,
    //                 examples: [
    //                     {
    //                         description: 'This is a license plate from Washington, USA, but its plate number is obscured. Please mark the country and state correctly, and check the <code>Unclear?</code> checkbox.',
    //                         positive: true,
    //                         image: 'obscured1.jpg'
    //                     },
    //                     {
    //                         description: 'This is piece of paper over a license plate. You cannot tell the country/state, so leave those fields blank and check the <code>Unclear?</code> checkbox.',
    //                         positive: true,
    //                         image: 'obscured2.jpg'
    //                     }
    //                 ]
    //             },
    //             {
    //                 header: 'Check <code>Partial</code> for plates where a part of the central plate number is not readable, but the rest of the plate is.',
    //                 description: `
    //                     If there is anything blocking part of the plate, where you can read some of the letters, but not all, please mark <code>Partial</code>.
    //                 `,
    //                 examples: [
    //                     {
    //                         description: 'This license plate has a pole blocking some of the numbers, please mark partial.',
    //                         positive: true,
    //                         image:'partial.jpg'
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // },
    front_back: {
        front_back: {
            id: 39,
            header: 'Front or Back License Plate',
            description: `
                In this task, you will be assigning a plate as a front, back, or unattached plate. This is to label plates attached to the front of the car, the back or ones that are not attached.
                If you are unsure, please use the skip button.
            `,
            workflow: [
                'Identify if a plate is a front, back, or unattached plate.',
                'Click the buttons or use the keyboard keys <code>Up</code> for front, <code>down</code> for back, and <code>?</code> for unattached.'

            ],
            rules: [
                {
                    header: 'Unattached License plates',
                    description: `
                        For any license plate that is not attached on the front or back of the car, please use unattached.
                    `,
                    examples: [
                        {
                            description: 'This is not attached on a typical portion of the car where license plates are attached, so this is considered unattached.',
                            positive: true,
                            image: 'unattached.jpg'
                        }
                    ]
                }
            ]
        }
    }
}

let TASKS_BY_ID = {};
Object.keys(TASKS).forEach(category => {
    Object.keys(TASKS[category]).forEach(name => {
        TASKS_BY_ID[TASKS[category][name].id] = {
            name: name,
            category: category
        };
    });
});

export {
    TASKS,
    TASKS_BY_ID
};
